import React, { useState, useEffect } from "react";
import {
  FaRegFileAlt,
  FaDollarSign,
  FaImage,
  FaMapMarkerAlt,
  FaCogs,
  FaFileUpload,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import KitchenDetails from "./KitchenDetails";
import KitchenPrice from "./KitchenPrice";
import KitchenImages from "./KitchenImages";
import KitchenLocation from "./KitchenLocation";
import KitchenAmenities from "./KitchenAmenities";
import KitchenDocuments from "./KitchenDocuments";
import "./AddKitchen.css";
import axios from "axios";
import config from "../../config";

const AddKitchen = ({ editMode }) => {
  const renderIcons = () => (
    <ul className="icon-view">
      <li className={step === 1 ? "active" : ""} onClick={() => setStep(1)}>
        <FaRegFileAlt />
        {/* <p>Description</p> */}
      </li>
      <li className={step === 2 ? "active" : ""} onClick={() => setStep(2)}>
        <FaDollarSign />
        {/* <p>Price</p> */}
      </li>
      <li className={step === 3 ? "active" : ""} onClick={() => setStep(3)}>
        <FaImage />
        {/* <p>Images</p> */}
      </li>
      <li className={step === 4 ? "active" : ""} onClick={() => setStep(4)}>
        <FaMapMarkerAlt />
        {/* <p>Location</p> */}
      </li>
      <li className={step === 5 ? "active" : ""} onClick={() => setStep(5)}>
        <FaFileUpload />
      </li>
      <li className={step === 6 ? "active" : ""} onClick={() => setStep(6)}>
        <FaCogs />
      </li>
    </ul>
  );

  const { kitchenID } = useParams();
  const [step, setStep] = useState(1);
  const [kitchenData, setKitchenData] = useState({
    // Initialize with default or empty values
    kitchenID: "",
    title: "",
    category: "",
    size: "",
    city: "",
    neighborhood: "",
    description: "",
    checkinmessage: "",
    agreed: false,
    bookingType: "hour",
    hourlyRate: "",
    dayRate: "",
    cleaningFee: "",
    minBooking: "",
    securityDeposit: "",
    rateEntries: [],
    images: [],
    address: "",
    latitude: null,
    longitude: null,
    amenities: {},
    documents: [],
  });

  useEffect(() => {
    if (editMode && kitchenID) {
      const fetchKitchenData = async () => {
        try {
          const response = await axios.get(
            `${config.apiBaseUrl}/api/kitchens/kitchen/${kitchenID}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          // Map the response data to match the expected field names in your components
          const fetchedData = {
            KitchenID: response.data.KitchenID,
            title: response.data.Title,
            category: response.data.Category,
            size: response.data.Size,
            city: response.data.City,
            neighborhood: response.data.Neighborhood,
            description: response.data.Description,
            checkinmessage: response.data.CheckinMessage,
            agreed: response.data.Agreed,
            bookingType: response.data.BookingType,
            hourlyRate: response.data.HourlyRate,
            dayRate: response.data.DayRate,
            cleaningFee: response.data.CleaningFee,
            minBooking: response.data.MinBooking,
            securityDeposit: response.data.SecurityDeposit,
            rateEntries: response.data.RateEntries || [],
            images: response.data.Images || [],
            address: response.data.Address,
            latitude: response.data.Latitude,
            longitude: response.data.Longitude,
            amenities: response.data.Amenities || {},
            Documents: response.data.Documents || [],
          };
          setKitchenData(fetchedData); // Populate data for editing
        } catch (error) {
          console.error("Error fetching kitchen data:", error);
        }
      };
      fetchKitchenData();
    } else {
      setKitchenData({
        kitchenID: "",
        title: "",
        category: "",
        size: "",
        city: "",
        neighborhood: "",
        description: "",
        checkinmessage: "",
        agreed: false,
        bookingType: "hour",
        hourlyRate: "",
        dayRate: "",
        cleaningFee: "",
        minBooking: "",
        securityDeposit: "",
        rateEntries: [],
        images: [],
        address: "",
        latitude: null,
        longitude: null,
        amenities: {},
        documents: [],
      });
      setStep(1);
    }
  }, [editMode, kitchenID]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const updateKitchenData = (newData) => {
    setKitchenData((prevData) => ({ ...prevData, ...newData }));
  };

  // Handling the rendering of each step based on the current step
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <KitchenDetails
            nextStep={nextStep}
            editMode={editMode}
            kitchenData={kitchenData}
            updateKitchenData={updateKitchenData}
          />
        );
      case 2:
        return (
          <KitchenPrice
            prevStep={prevStep}
            nextStep={nextStep}
            editMode={editMode}
            kitchenData={kitchenData}
            updateKitchenData={updateKitchenData}
          />
        );
      case 3:
        return (
          <KitchenImages
            prevStep={prevStep}
            nextStep={nextStep}
            editMode={editMode}
            kitchenData={kitchenData}
            updateKitchenData={updateKitchenData}
          />
        );
      case 4:
        return (
          <KitchenLocation
            prevStep={prevStep}
            nextStep={nextStep}
            editMode={editMode}
            kitchenData={kitchenData}
            updateKitchenData={updateKitchenData}
          />
        );
      case 5:
        return (
          <KitchenDocuments
            prevStep={prevStep}
            nextStep={nextStep}
            editMode={editMode}
            kitchenData={kitchenData}
            updateKitchenData={updateKitchenData}
          />
        );
      case 6:
        return (
          <KitchenAmenities
            prevStep={prevStep}
            editMode={editMode}
            kitchenData={kitchenData}
            updateKitchenData={updateKitchenData}
          />
        );
      default:
        // return <KitchenDetails nextStep={nextStep} />;
        return null;
    }
  };

  return (
    <div className="add-kitchen">
      <h1>{editMode ? "Edit Kitchen" : "Add New Kitchen"}</h1>
      <div className="progress-indicator">
        {window.innerWidth <= 768 ? (
          renderIcons()
        ) : (
          <ul>
            <li
              className={step === 1 ? "active" : ""}
              onClick={() => setStep(1)}
            >
              Description
            </li>
            <li
              className={step === 2 ? "active" : ""}
              onClick={() => setStep(2)}
            >
              Price
            </li>
            <li
              className={step === 3 ? "active" : ""}
              onClick={() => setStep(3)}
            >
              Images
            </li>
            <li
              className={step === 4 ? "active" : ""}
              onClick={() => setStep(4)}
            >
              Location
            </li>
            <li
              className={step === 5 ? "active" : ""}
              onClick={() => setStep(5)}
            >
              Documents
            </li>
            <li
              className={step === 6 ? "active" : ""}
              onClick={() => setStep(6)}
            >
              Amenities
            </li>
          </ul>
        )}
      </div>
      <div className="step-content">{renderStep()}</div>
    </div>
  );
};

export default AddKitchen;
