import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./MapComponent.css";

const MapComponent = ({ kitchens }) => {
  const navigate = useNavigate();
  const mapContainerRef = useRef(null); 
  const mapRef = useRef(null); 
  const circlesRef = useRef([]); 

  useEffect(() => {
    if (window.google && kitchens.length > 0 && mapContainerRef.current) {
      // Initialize the map only once
      if (!mapRef.current) {
        const firstValidKitchen = kitchens.find(
          (kitchen) =>
            typeof kitchen.Latitude === "number" &&
            typeof kitchen.Longitude === "number"
        );

        if (!firstValidKitchen) {
          console.error("No kitchen has valid latitude and longitude.");
          return;
        }

        mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
          center: {
            lat: firstValidKitchen.Latitude,
            lng: firstValidKitchen.Longitude,
          },
          zoom: 10,
        });

        // Create InfoWindow instance outside the loop to reuse
        const infoWindow = new window.google.maps.InfoWindow();

        // Add circles for each kitchen
        kitchens.forEach((kitchen) => {
          const { Latitude, Longitude, Title, KitchenID } = kitchen;

          if (typeof Latitude !== "number" || typeof Longitude !== "number") {
            console.warn(`Invalid coordinates for kitchen ID: ${KitchenID}`);
            return; // Skip this kitchen
          }

          // Add circle on the map
          const circle = new window.google.maps.Circle({
            map: mapRef.current,
            center: { lat: Latitude, lng: Longitude },
            radius: 1546.72, 
            fillColor: "#FF0000",
            fillOpacity: 0.2,
            strokeColor: "#FF0000",
            strokeOpacity: 0.5,
            strokeWeight: 2,
          });

          // Hover event: Display InfoWindow on mouseover
          circle.addListener("mouseover", () => {
            infoWindow.setContent(`
              <div class="info-bubble">
                <h3 class="info-bubble-title">${Title}</h3>
                <p class="info-bubble-rate">Explore kitchens near this area.</p>
              </div>
            `);
            infoWindow.setPosition(circle.getCenter());
            infoWindow.open(mapRef.current);
          });

          // Hide InfoWindow on mouseout
          circle.addListener("mouseout", () => {
            infoWindow.close();
          });

          // Double-click event to navigate to kitchen details
          circle.addListener("dblclick", () => {
            navigate(`/kitchen/${KitchenID}`);
          });

          // Store circle instance for potential cleanup or future use
          circlesRef.current.push(circle);
        });

        // Adjust map bounds to fit all circles
        const bounds = new window.google.maps.LatLngBounds();
        kitchens.forEach((kitchen) => {
          const { Latitude, Longitude } = kitchen;
          if (typeof Latitude === "number" && typeof Longitude === "number") {
            bounds.extend({ lat: Latitude, lng: Longitude });
          }
        });
        mapRef.current.fitBounds(bounds);
      } else {
        // If map already initialized, clear existing circles and add new ones
        circlesRef.current.forEach((circle) => circle.setMap(null));
        circlesRef.current = [];

        const infoWindow = new window.google.maps.InfoWindow();

        // Add new circles
        kitchens.forEach((kitchen) => {
          const { Latitude, Longitude, Title, KitchenID } = kitchen;

          if (typeof Latitude !== "number" || typeof Longitude !== "number") {
            console.warn(`Invalid coordinates for kitchen ID: ${KitchenID}`);
            return; 
          }

          // Add circle with 5-mile radius (approx 8046.72 meters)
          const circle = new window.google.maps.Circle({
            map: mapRef.current,
            center: { lat: Latitude, lng: Longitude },
            radius: 1550,
            fillColor: "#FF0000",
            fillOpacity: 0.2,
            strokeColor: "#FF0000",
            strokeOpacity: 0.5,
            strokeWeight: 2,
          });

          // Hover event: Display InfoWindow on mouseover
          circle.addListener("mouseover", () => {
            infoWindow.setContent(`
              <div class="info-bubble">
                <h3 class="info-bubble-title">${Title}</h3>
                <p class="info-bubble-rate">Explore kitchens near this area.</p>
              </div>
            `);
            infoWindow.setPosition(circle.getCenter());
            infoWindow.open(mapRef.current);
          });

          // Hide InfoWindow on mouseout
          circle.addListener("mouseout", () => {
            infoWindow.close();
          });

          // Double-click event to navigate to kitchen details
          circle.addListener("dblclick", () => {
            navigate(`/kitchen/${KitchenID}`);
          });

          // Store circle instance for potential cleanup or future use
          circlesRef.current.push(circle);
        });

        // Adjust map bounds to fit all circles
        const bounds = new window.google.maps.LatLngBounds();
        kitchens.forEach((kitchen) => {
          const { Latitude, Longitude } = kitchen;
          if (typeof Latitude === "number" && typeof Longitude === "number") {
            bounds.extend({ lat: Latitude, lng: Longitude });
          }
        });
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [kitchens, navigate]);

  // Cleanup circles on unmount
  useEffect(() => {
    return () => {
      circlesRef.current.forEach((circle) => circle.setMap(null));
      circlesRef.current = [];
    };
  }, []);

  return (
    <div
      id="map"
      className="map-component"
      ref={mapContainerRef}
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default MapComponent;
