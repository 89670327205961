import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import config from "../config";

const FoodContext = createContext();

export const useFood = () => useContext(FoodContext);

export const FoodProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [allFoodItems, setAllFoodItems] = useState([]);
  const [foodData, setFoodData] = useState({
    name: "",
    category: "",
    ingredients: "",
    price: "",
    size: "",
    serves: "",
    restaurantID: "",
    userID: "",
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoadingUser(false);
  }, []);

  const createFoodItem = async (foodItemData) => {
    console.log("User ID:", user.userID);
    const response = await axios.post('/api/food-items/add', foodItemData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  };

  const updateFoodItem = async (foodID, updatedData) => {
    if (!user) {
      throw new Error("User not authenticated");
    }
    try {
      const response = await axios.put(
        `${config.apiBaseUrl}/api/food-items/edit/${foodID}`,
        updatedData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setAllFoodItems((prev) =>
        prev.map((item) =>
          item.foodID === foodID ? { ...item, ...updatedData } : item
        )
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to update food item");
    }
  };

  const fetchAllFoodItems = useCallback(async ({ userID = null } = {}) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/food-items/all`,
        {
          params: { userID },
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setAllFoodItems(response.data || []);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch food items");
    }
  }, []);

  const deleteFoodItem = async (foodID) => {
    if (!user) {
      throw new Error("User not authenticated");
    }
    try {
      await axios.delete(`${config.apiBaseUrl}/api/food-items/delete/${foodID}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setAllFoodItems((prev) => prev.filter((item) => item.foodID !== foodID));
    } catch (error) {
      throw new Error("Failed to delete food item");
    }
  };

  const fetchRestaurants = async (userID) => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/restaurants/user/${userID}`, {
        params: { userID },
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response.data.map(restaurant => restaurant.name);
    } catch (error) {
      console.error("Error fetching restaurants:", error.response?.data || error.message);
      throw new Error("Failed to fetch restaurants");
    }
  };

  const fetchFoodByUser = async (userID) => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/food-items/by-user/${userID}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching food by user:", error.response?.data || error.message);
      throw new Error("Failed to fetch food by user");
    }
  };

  const contextValue = useMemo(
    () => ({
      user,
      loadingUser,
      allFoodItems,
      setAllFoodItems,
      foodData,
      setFoodData,
      createFoodItem,
      updateFoodItem,
      fetchAllFoodItems,
      deleteFoodItem,
      fetchRestaurants,
      fetchFoodByUser,
    }),
    [
      user,
      loadingUser,
      allFoodItems,
      setAllFoodItems,
      foodData,
      createFoodItem,
      updateFoodItem,
      fetchAllFoodItems,
      deleteFoodItem,
      fetchRestaurants,
      fetchFoodByUser,
    ]
  );

  return <FoodContext.Provider value={contextValue}>{children}</FoodContext.Provider>;
};
