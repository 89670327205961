import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaLinkedin, FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import NewsletterPopup from "../NewsletterPopup/NewsletterPopup"; // Import NewsletterPopup component
import config from "../../config";
import "./Footer.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [popup, setPopup] = useState({ show: false, message: "", type: "" });

  const handleNewsletterSubmit = async () => {
    if (!email.trim()) {
      setPopup({
        show: true,
        message: "Please enter a valid email address.",
        type: "error",
      });
      return;
    }

    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/newsletter`, {
        email,
      });

      if (response.data.exists) {
        setPopup({
          show: true,
          message: response.data.message,
          type: "error",
        });
      } else {
        setPopup({
          show: true,
          message: response.data.message,
          type: "success",
        });
        setEmail(""); // Clear the input field
      }
    } catch (error) {
      setPopup({
        show: true,
        message:
          error.response?.data?.message ||
          "An error occurred. Please try again later.",
        type: "error",
      });
    }
  };

  const closePopup = () => {
    setPopup({ show: false, message: "", type: "" });
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-social">
          <span className="footer-title">STAY CONNECTED WITH US</span>
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/company/airkitchenz/"
              className="social-icon"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.instagram.com/airkitchenz/"
              className="social-icon"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.facebook.com/AirKitchenz"
              className="social-icon"
            >
              <FaFacebook />
            </a>
            <a href="https://x.com/AirKitchenz" className="social-icon">
              <FaXTwitter />
            </a>
            <a
              href="https://www.tiktok.com/@airkitchenzofficial"
              className="social-icon"
            >
              <FaTiktok />
            </a>
          </div>
          <div className="divider"></div>
          <div className="join-us">
            <span className="footer-title">Newsletter</span>
            <div className="email-subscribe">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={handleNewsletterSubmit}>➔</button>
            </div>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-logo">
            <img
              src={
                "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/assets/footer-logo.png"
              }
              alt="AirKitchenz Logo"
            />
          </div>
          <div className="footer-section">
            <h3>AirKitchenz AI</h3>
            <ul>
              <li>
                <Link to="/aboutus">About AirKitchenz</Link>
              </li>
              <li>
                <Link to="/blogs">Blog</Link>
              </li>
              <li>
                <Link to="/faqs">FAQs</Link>
              </li>
              <li>
                <Link to="/careerspage">Careers</Link>
              </li>
              <li>
                <a
                  href="mailto:contact@airkitchenz.com?subject=Inquiry&body=Hi, I would like to learn more about AirKitchenz."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Business</h3>
            <ul>
              <li>
                <a href="/browse">Kitchen Rentals</a>
              </li>
              <li>
                <a href="/browse">Find a Kitchen</a>
              </li>
              <li>
                <a href="/aibuilder">AI Business builder</a>
              </li>
              <li>
                <a href="https://eatz4catering.com/">Eatz4Catering</a>
              </li>
              <li>
                <a href="/features-services">Features and Services</a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Social Media</h3>
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/airkitchenz/">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/airkitchenz/">Instagram</a>
              </li>
              <li>
                <a href="https://www.facebook.com/AirKitchenz">Facebook</a>
              </li>
              <li>
                <a href="https://x.com/AirKitchenz">Twitter</a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@airkitchenzofficial">Tiktok</a>
              </li>
            </ul>
          </div>
          {popup.show && (
            <NewsletterPopup
              message={popup.message}
              type={popup.type}
              onClose={closePopup}
            />
          )}
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2025 AirKitchenz. All rights reserved.</p>
        <div className="footer-policy">
          <Link to="/termsofuse">Terms of Use</Link>
          <Link to="/privacypolicy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
