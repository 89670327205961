import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import config from "../config";

const KitchenContext = createContext();

// Custom hook to use the Kitchen context
const useKitchen = () => useContext(KitchenContext);

// Context provider
const KitchenProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [kitchens, setKitchens] = useState([]);
  const [kitchenID, setKitchenID] = useState(null);
  const [allKitchens, setAllKitchens] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [kitchenData, setKitchenData] = useState({
    title: "",
    category: "",
    size: "",
    city: "",
    neighborhood: "",
    description: "",
    hourlyRate: "",
    dayRate: "",
    cleaningFee: "",
    securityDeposit: "",
    rateEntries: [],
    blockedDates: [],
    images: [],
    address: "",
    latitude: null,
    longitude: null,
    amenities: [],
    kitchenStatus: "draft",
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoadingUser(false);
  }, []);

  // Create kitchen (first step)
  const createKitchen = async (initialData) => {
    if (!user) {
      console.error("User is not authenticated or user data is missing.");
      throw new Error("User not authenticated");
    }
    console.log("[KitchenContext] Creating kitchen with given data.");
    try {
      const dataToSend = {
        ...initialData,
        userID: user.userID,
        userEmail: user.email,
        userFirstName: user.firstName,
        images: initialData.images || [],
        agreed: initialData.agreed || false,
        latitude: initialData.latitude || null,
        longitude: initialData.longitude || null,
      };
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kitchens/add`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setKitchenID(response.data.kitchenID);
      setKitchenData((prevData) => ({ ...prevData, ...initialData }));

      const kitchens = await fetchAllKitchens();
      setAllKitchens(kitchens);
    } catch (error) {
      console.error(
        "Error creating kitchen:",
        error.response ? error.response.data : error.message
      );
      throw new Error("Failed to create kitchen");
    }
  };

  // Update kitchen (subsequent steps)
  const updateKitchen = async (updatedData) => {
    if (!user) {
      console.error("User is not authenticated or user data is missing.");
      throw new Error("User not authenticated");
    }
    console.log("[KitchenContext] Updating kitchen.");
    try {
      const newKitchenData = {
        ...kitchenData,
        ...updatedData,
        kitchenStatus:
          updatedData.kitchenStatus || kitchenData.kitchenStatus || "draft",
        latitude: updatedData.latitude || kitchenData.latitude || null,
        longitude: updatedData.longitude || kitchenData.longitude || null,
      };

      setKitchenData(newKitchenData);

      if (kitchenID) {
        await axios.put(
          `${config.apiBaseUrl}/api/kitchens/edit/${kitchenID}`,
          newKitchenData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
    } catch (error) {
      console.error(
        "Error updating kitchen:",
        error.response ? error.response.data : error.message
      );
      throw new Error("Failed to update kitchen");
    }
  };

  // Convert fetchAllKitchens to useCallback
  const fetchAllKitchens = useCallback(
    async ({
      userID = null,
      lastEvaluatedKey = null,
      forceRefresh = false,
    } = {}) => {
      const CACHE_DURATION = 5 * 60 * 1000; 
      const now = Date.now();

      if (
        !forceRefresh &&
        lastFetchTime &&
        now - lastFetchTime < CACHE_DURATION &&
        allKitchens.length > 0 &&
        !userID
      ) {
        return allKitchens;
      }

      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/api/kitchens/all-kitchens`,
          {
            params: { userID, lastEvaluatedKey },
          }
        );
        const kitchensData = response.data.items || [];
        setKitchens(kitchensData);
        setAllKitchens(kitchensData);
        setLastFetchTime(now);
        return kitchensData;
      } catch (error) {
        console.log(error);
        console.log(
          "Error fetching kitchens:",
          error.response ? error.response.data : error.message
        );
        throw new Error("Failed to fetch kitchens");
      }
    },
    [allKitchens]
  );

  // Searching kitchen based on the filter expressions for browse page
  const searchKitchens = async (filters) => {
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kitchens/search`,
        filters,
        {
          // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setKitchens(response.data || []);
      return response.data || [];
    } catch (error) {
      console.error(
        "Error searching kitchens:",
        error.response ? error.response.data : error.message
      );
      return [];
    }
  };

  // Get the kitchen for the kitchen booking page
  const fetchKitchenById = async (kitchenID) => {
    // if (!user) throw new Error("User not authenticated");
    console.log("[KitchenContext] Fetching kitchen by ID.");
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/kitchens/kitchen/${kitchenID}`,
        {
          // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setKitchenData(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching kitchen by ID:", error);
      throw new Error("Failed to fetch kitchen data.");
    }
  };

  // kitchencontexts.js
  const updateKitchenEditMode = async (kitchenIDParam, updatedData) => {
    if (!user) {
      console.error("User is not authenticated or user data is missing.");
      throw new Error("User not authenticated");
    }

    try {
      const response = await axios.put(
        `${config.apiBaseUrl}/api/kitchens/edit/${kitchenIDParam}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Update kitchenData in context
      setKitchenData(updatedData);

      return response.data;
    } catch (error) {
      console.error(
        "Error updating kitchen:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      kitchenData,
      kitchenID,
      user,
      loadingUser,
      allKitchens,
      setAllKitchens,
      createKitchen,
      updateKitchen,
      fetchAllKitchens,
      fetchKitchenById,
      searchKitchens,
      updateKitchenEditMode,
    }),
    [
      kitchenData,
      kitchenID,
      user,
      loadingUser,
      allKitchens,
      fetchAllKitchens,
      createKitchen,
      updateKitchen,
      fetchKitchenById,
      searchKitchens,
      updateKitchenEditMode,
      setAllKitchens,
    ]
  );

  return (
    <KitchenContext.Provider value={contextValue}>
      {children}
    </KitchenContext.Provider>
  );
};

export { KitchenProvider, useKitchen };
