import React, { useState, useEffect, useRef, useContext } from "react";
import { useKitchen } from "../../context/KitchenContext";
import { FaTimes } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import "./KitchenDetails.css";
import config from "../../config";

// Function to load Google Maps script
const loadGoogleMapsScript = (callback) => {
  if (!document.querySelector(`[src*="maps.googleapis.com"]`)) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.mapApiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => callback();
  } else {
    callback();
  }
};

const KitchenDetails = ({
  nextStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const { createKitchen, updateKitchenEditMode } = useKitchen();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Form state
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [size, setSize] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [description, setDescription] = useState("");
  const [checkinmessage, setCheckinMessage] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Initialize form with kitchenData or localStorage data
  useEffect(() => {
    // Check for saved form data in localStorage
    const savedFormData = localStorage.getItem("kitchenFormData");
    console.log("Form Data in localStorage", savedFormData);
    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);
      setTitle(parsedData.title || "");
      setCategory(parsedData.category || "");
      setSize(parsedData.size || "");
      setCity(parsedData.city || "");
      setNeighborhood(parsedData.neighborhood || "");
      setDescription(parsedData.description || "");
      setCheckinMessage(parsedData.checkinmessage || "");
      setAgreed(parsedData.agreed || false);

      localStorage.removeItem("kitchenFormData");
    } else if (editMode && kitchenData) {
      // Populate form with existing kitchen data in edit mode
      setTitle(kitchenData.title || "");
      setCategory(kitchenData.category || "");
      setSize(kitchenData.size || "");
      setCity(kitchenData.city || "");
      setNeighborhood(kitchenData.neighborhood || "");
      setDescription(kitchenData.description || "");
      setCheckinMessage(kitchenData.checkinmessage || "");
      setAgreed(kitchenData.agreed || false);
    } else {
      setTitle("");
      setCategory("");
      setSize("");
      setCity("");
      setNeighborhood("");
      setDescription("");
      setCheckinMessage("");
      setAgreed(false);
    }
  }, [editMode, kitchenData]);

  // Load Google Maps Autocomplete
  useEffect(() => {
    loadGoogleMapsScript(() => {
      if (window.google && window.google.maps) {
        const cityInput = document.getElementById("city");

        if (cityInput) {
          const cityAutocomplete = new window.google.maps.places.Autocomplete(
            cityInput,
            { types: ["(cities)"] }
          );
          cityAutocomplete.addListener("place_changed", () => {
            const place = cityAutocomplete.getPlace();
            if (place.address_components) {
              setCity(place.formatted_address);
            }
          });
        } else {
          console.error("City input element not found.");
        }
      } else {
        console.error("Google Maps API not loaded.");
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const kitchenDataToUpdate = {
      title,
      category,
      size,
      city,
      neighborhood,
      description,
      checkinmessage,
      agreed,
    };

    const updatedKitchenData = { ...kitchenData, ...kitchenDataToUpdate };

    // If user is not logged in, save form data and redirect to login
    if (!user) {
      console.log("User not logged in. Saving form data to localStorage.");
      localStorage.setItem(
        "kitchenFormData",
        JSON.stringify(kitchenDataToUpdate)
      );
      console.log(
        "Saved kitchenFormData to localStorage.",
        localStorage.getItem("kitchenFormData")
      );

      // Redirect to login page with redirect path
      // Using the current location to redirect back after login
      navigate("/login", { state: { from: location.pathname } });
      return;
    }

    setLoading(true);
    try {
      if (editMode) {
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        await createKitchen(updatedKitchenData);
      }
      updateKitchenData(kitchenDataToUpdate);
      nextStep();
    } catch (error) {
      const errorMsg = error.response?.data?.message || error.message;
      setMessage({
        type: "error",
        text: editMode
          ? `Failed to update the kitchen: ${errorMsg}`
          : `Failed to create the kitchen: ${errorMsg}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="new-kitchen-details">
        <h2 className="form-title">
          {editMode ? "Edit Description" : "Add Description"}
        </h2>
        {message.text && (
          <div className={`message ${message.type}`}>{message.text}</div>
        )}
        <div className="form-row">
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter your kitchen title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required
            />
          </div>
          <div className="form-group">
            <label>Kitchen Type</label>
            <select
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <option value="">Select Category</option>
              <option value="commercial">Commercial Kitchen</option>
              <option value="restaurant">Restaurant Kitchen</option>
              <option value="ghost">Ghost Kitchen</option>
              <option value="bakery">Bakery Kitchen</option>
              <option value="private">Private Kitchen</option>
              <option value="all">All Purpose Kitchen</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Enter your city</label>
            <input
              type="text"
              id="city"
              placeholder="Type the city name"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label>Kitchen Size</label>
            <select
              value={size}
              onChange={(e) => {
                setSize(e.target.value);
              }}
            >
              <option value="">Select Size</option>
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="large">Large</option>
            </select>
          </div>
        </div>

        {/* <div className="form-row">
          <div className="form-group">
            <label>Neighbourhood / Area</label>
            <input
              type="text"
              id="neighborhood"
              placeholder="Type the neighbourhood name"
              value={neighborhood}
              onChange={(e) => {
                setNeighborhood(e.target.value);
              }}
            />
          </div>
        </div> */}

        <div className="form-group">
          <label>Kitchen Description</label>
          <textarea
            rows="4"
            placeholder="Describe your Kitchen"
            maxLength="500"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <small className="char-limit">
            Note: This message will appear below your kitchen listing.
          </small>
        </div>
        <div className="form-group">
          <label>Check-in Message</label>
          <textarea
            rows="6"
            placeholder="Check-In Message for the Renter"
            maxLength="500"
            value={checkinmessage}
            onChange={(e) => {
              setCheckinMessage(e.target.value);
            }}
          />
          <small className="char-limit">
            Note: Please do not share personal information as per company
            policy. All communication will be handled through AirKitchenz. This
            message will be shown to user only after the booking confirmation.
            Kindly include the kitchen key or code information.
          </small>
        </div>
        <div className="terms-container">
          <input
            type="checkbox"
            id="terms"
            checked={agreed}
            onChange={(e) => {
              setAgreed(e.target.checked);
            }}
          />
          <label htmlFor="terms" onClick={toggleModal}>
            I agree to the{" "}
            <span className="kitchen-terms-link">Terms and Condition</span>
          </label>
        </div>
        <div className="navigation-buttons">
          <button type="submit" className="next-btn" disabled={!agreed}>
            {loading ? "Saving..." : editMode ? "Update" : "Next"}
          </button>
          {loading && (
            <div className="loading-indicator">
              {editMode
                ? "Updating your kitchen details..."
                : "Saving your kitchen details..."}
            </div>
          )}
        </div>
      </form>

      {showModal && (
        <div className="tc-modal-overlay" onClick={toggleModal}>
          <div
            className="tc-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <FaTimes className="tc-modal-close-icon" onClick={toggleModal} />
            <h3>Terms and Conditions</h3>
            <div className="tc-modal-body">
              <p>
                <strong>Kitchen Owner Agreement</strong>
              </p>

              <p>
                <strong>Introduction:</strong>
                <br />
                This Kitchen Owner Agreement (“Agreement”) is made between
                AirKitchenz (“Platform”) and the Kitchen Owner (“Owner”). By
                listing your kitchen space on AirKitchenz, you agree to comply
                with the following terms and conditions.
              </p>

              <p>
                <strong>1. Kitchen and Use of Kitchen Space</strong>
                <br />
                1.1 The Owner agrees to list their kitchen space (the “Kitchen”)
                on the Platform for use by food businesses.
                <br />
                1.2 The Owner must provide accurate and up-to-date information
                regarding the kitchen space, including its location, available
                equipment, and operating hours.
                <br />
                1.3 The Owner must upload clear and high-quality pictures of the
                Kitchen to accurately represent its features and condition.
              </p>

              <p>
                <strong>2. Compliance with Laws and Regulations</strong>
                <br />
                2.1 The Owner is responsible for ensuring that their kitchen
                space complies with all applicable health, safety, and licensing
                regulations.
                <br />
                2.2 The Owner must upload the following documentation to the
                Platform:
                <br />
                • Current and valid health department license
                <br />
                • Business license
                <br />
                • Liability insurance
                <br />
                • Workers’ compensation insurance
                <br />
                • Proof of identity (driver’s license or passport)
                <br />
                2.3 The Platform may request proof of compliance at any time.
              </p>

              <p>
                <strong>3. Maintenance and Upkeep</strong>
                <br />
                3.1 The Owner agrees to maintain the kitchen space in a clean,
                safe, and operational condition.
                <br />
                3.2 Any issues or repairs needed must be promptly addressed by
                the Owner.
                <br />
                3.3 The Owner shall promptly respond to inquiries and booking
                requests from potential renters through the Platform.
                <br />
                3.4 The Owner shall ensure that the Kitchen is available for use
                by renters during the agreed-upon booking period.
              </p>

              <p>
                <strong>4. Access and Instructions for Use</strong>
                <br />
                4.1 The Owner must provide clear and detailed instructions on
                how to access the kitchen space, including any necessary keys,
                codes, or access cards.
                <br />
                4.2 Instructions must also include directions on how to operate
                lights, equipment, and any other specific requirements to ensure
                seamless access and use of the kitchen space.
                <br />
                4.3 The Owner should provide any additional information or
                guidance to facilitate a smooth experience for the kitchen
                renter.
              </p>

              <p>
                <strong>5. Security Deposit and Damage Protection</strong>
                <br />
                5.1 The Platform will collect a security deposit from the
                Kitchen Renter at the time of booking. This deposit will be held
                by the Platform until 48 hours after the Kitchen booking is
                finalized.
                <br />
                5.2 The Kitchen Owner has 48 hours after the booking period ends
                to report any issues or damages to the Kitchen or equipment.
                <br />
                5.3 If no issues or damages are reported within this timeframe,
                the Platform will return the security deposit to the Kitchen
                Renter.
                <br />
                5.4 The Platform will also collect two forms of credit cards
                from the Kitchen Renter. These credit cards may be charged for
                any damages exceeding the amount of the security deposit to
                ensure the protection of the Kitchen Owner.
              </p>

              <p>
                <strong>6. Platform Fees and Payments</strong>
                <br />
                6.1 The Owner acknowledges that the Platform charges a
                commission of 15% on the total booking amount as AirKitchenz
                fees.
                <br />
                6.2 The Owner acknowledges that the Platform also charges a
                credit card processing fee of 2.9% plus $0.30 per transaction,
                which represents the direct credit card merchant fees.
                <br />
                6.3 The Owner agrees to pay the applicable AirKitchenz fees and
                credit card processing fees as outlined above.
                <br />
                6.4 The Owner will receive the payment for the kitchen rental
                seven business days after the date the kitchen was rented, minus
                the 15% AirKitchenz commission and the 2.9% plus $0.30 credit
                card merchant fee.
                <br />
                6.5 The Owner acknowledges that AirKitchenz reserves the right
                to add, change, increase, or decrease any fees without prior
                notice.
              </p>

              <p>
                <strong>7. Rating and Reviews System</strong>
                <br />
                7.1 The Platform includes a rating and review system where users
                can provide feedback on their experience with the kitchen space.
                <br />
                7.2 The Owner agrees to receive and respond to ratings and
                reviews in a professional manner.
                <br />
                7.3 The Owner understands that consistent negative feedback or
                failure to address issues raised in reviews may result in
                removal from the Platform.
                <br />
                7.4 The Platform reserves the right to remove any reviews that
                violate its guidelines or terms.
              </p>

              <p>
                <strong>8. Liability and Indemnification</strong>
                <br />
                8.1 The Owner acknowledges that the Platform acts solely as a
                facilitator for connecting Owners with potential renters and
                shall not be liable for any damages, losses, or disputes arising
                from the use of the Kitchen.
                <br />
                8.2 The Owner and the Kitchen Renter must provide proof of
                insurance and identification (driver’s license or passport) to
                cover any liability or damage occurring during the rental
                period.
                <br />
                8.3 The Kitchen Renter is responsible for any liability that
                arises during the rental period, and the Platform may utilize
                the security deposit and the second form of credit card payment
                to cover damages.
                <br />
                8.4 The Owner agrees to indemnify and hold harmless the
                Platform, its affiliates, and their respective officers,
                directors, employees, and agents from any claims, damages, or
                liabilities arising out of the Owner’s listing and rental of the
                Kitchen. This includes, but is not limited to, reimbursement of
                all attorneys’ fees and costs relating to a dispute with the
                Renter and/or for any third-party claim for injuries or property
                damage incurred by the Platform in connection with the rental of
                the Kitchen.
              </p>

              <p>
                <strong>9. Exclusivity Clause</strong>
                <br />
                9.1 The Owner agrees to list the Kitchen exclusively on the
                AirKitchenz platform for a period of 6 months from the date of
                listing submission or for as long as the Kitchen is listed on
                AirKitchenz, whichever is longer.
                <br />
                9.2 The Owner acknowledges that AirKitchenz is a market pioneer
                and commits to putting forth all efforts to develop and nurture
                this emerging market, aiming to support small businesses and
                kitchen owners to succeed through this partnership.
              </p>

              <p>
                <strong>10. Governing Law and Legal Protection</strong>
                <br />
                10.1 This Agreement shall be governed by and construed in
                accordance with the laws of the United States, and specifically,
                the laws of the states where the transactions take place.
                <br />
                10.2 Any disputes arising out of or in connection with this
                Agreement shall be subject to the exclusive jurisdiction of the
                courts in [Jurisdiction], to the fullest extent permitted by
                law.
              </p>

              <p>
                <strong>11. Communication</strong>
                <br />
                11.1 The Owner and renters agree that all communications
                regarding bookings, inquiries, or any other matters related to
                the Kitchen shall be conducted solely through the AirKitchenz
                platform messaging system.
                <br />
                11.2 The Owner and renters shall refrain from exchanging
                personal contact information, including but not limited to phone
                numbers, emails, or addresses, outside of the AirKitchenz
                platform.
                <br />
                11.3 Any party found to be in violation of this communication
                policy may be subject to penalties, including but not limited to
                suspension or permanent banning from the AirKitchenz platform.
              </p>

              <p>
                <strong>12. Entire Agreement</strong>
                <br />
                12.1 This Agreement constitutes the entire understanding and
                agreement between the Owner and the Platform regarding the
                listing of the Kitchen on the Platform.
              </p>

              <p>
                <strong>Acceptance:</strong>
                <br />
                By listing your kitchen space on AirKitchenz, you agree to the
                terms and conditions outlined in this Kitchen Owner Agreement.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KitchenDetails;
