import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaPlus, FaMinus } from "react-icons/fa";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./RestaurantPage.css";

const RestaurantPage = () => {
  const { name, restaurantID } = useParams();
  const decodedName = decodeURIComponent(name);
  const [restaurant, setRestaurant] = useState(null);
  const [foodItems, setFoodItems] = useState([]);
  const [cart, setCart] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        const response = await axios.get(`/api/restaurants/${restaurantID}`);
        setRestaurant(response.data);
      } catch (error) {
        console.error(
          "Error fetching restaurant data:",
          error.response ? error.response.data : error.message
        );
      }
    };

    const fetchFoodItems = async () => {
      try {
        const response = await axios.get(
          `/api/food-items/by-restaurant/${decodedName}`
        );
        setFoodItems(response.data);
      } catch (error) {
        console.error("Error fetching food items:", error);
      }
    };

    fetchRestaurantData();
    fetchFoodItems();
  }, [decodedName, restaurantID]);

  const handleAddToCart = (foodID) => {
    setCart((prevCart) => ({
      ...prevCart,
      [foodID]: (prevCart[foodID] || 0) + 1,
    }));
  };

  const handleRemoveFromCart = (foodID) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      if (updatedCart[foodID] > 1) {
        updatedCart[foodID] -= 1;
      } else {
        delete updatedCart[foodID];
      }
      return updatedCart;
    });
  };

  const handleCheckout = () => {
    // Logic for checkout (e.g., navigate to a checkout page or open a modal)
    alert("Proceeding to Checkout!");
  };

  const cartItems = foodItems.filter((item) => cart[item.foodID]);
  const filteredItems =
    selectedCategory === "All"
      ? foodItems
      : foodItems.filter((item) => item.category === selectedCategory);

  const categories = [
    "All",
    ...new Set(foodItems.map((item) => item.category)),
  ];

  const calculateTotal = () => {
    return cartItems
      .reduce((total, item) => total + item.price * cart[item.foodID], 0)
      .toFixed(2);
  };

  return (
    <div className="restaurant-page">
      <Navbar />

      {/* Header Section */}
      <header className="restaurant-header">
        {restaurant && (
          <motion.div
            className="header-content"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="header-image">
              <img
                src={
                  restaurant.images?.[0] ||
                  "https://via.placeholder.com/800x400"
                }
                alt={restaurant.name}
                className="restaurant-page-image"
              />
            </div>
            <div className="header-details">
              <h1>{restaurant.name}</h1>
              <p className="location">{restaurant.location}</p>
              <p>Minimum Order: ${restaurant.min_order}</p>
              <button
                className="view-menu-button"
                onClick={() => window.open(restaurant.menu_pdf_url, "_blank")}
              >
                View Menu
              </button>
            </div>
          </motion.div>
        )}
      </header>

      {/* Main Content */}
      <main className="restaurant-content">
        {/* Category Bar */}
        <motion.nav
          className="category-bar"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {categories.map((category) => (
            <motion.button
              key={category}
              className={`category-item ${
                selectedCategory === category ? "active" : ""
              }`}
              onClick={() => setSelectedCategory(category)}
              whileHover={{
                scale: 1.1,
                backgroundColor: "#e63946",
                color: "#fff",
              }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="label">{category}</span>
            </motion.button>
          ))}
        </motion.nav>

        {/* Food Items and Cart */}
        <div className="content-wrapper">
          {/* Food Items Section */}
          <motion.section
            className="food-items-section"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            {/* <h2>Available Food Items</h2> */}
            <div className="food-items-grid">
              {filteredItems.map((item) => (
                <motion.div
                  key={item.foodID}
                  className="food-card"
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)",
                  }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <h3>{item.name}</h3>
                  <p className="category">{item.category}</p>
                  <p className="ingredients">{item.ingredients}</p>
                  <p className="price">${item.price}</p>
                  <div className="quantity-controls">
                    <button onClick={() => handleRemoveFromCart(item.foodID)}>
                      <FaMinus />
                    </button>
                    <span>{cart[item.foodID] || 0}</span>
                    <button onClick={() => handleAddToCart(item.foodID)}>
                      <FaPlus />
                    </button>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.section>

          {/* Cart Section */}
          <motion.aside
            className="cart-section"
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.8 }}
          >
            <h2>Your Cart</h2>
            {cartItems.length === 0 ? (
              <p>Your cart is empty</p>
            ) : (
              <div>
                <ul className="cart-items">
                  {cartItems.map((item) => (
                    <li key={item.foodID} className="cart-item">
                      <span className="item-name">{item.name}</span>
                      <span className="item-quantity">
                        x {cart[item.foodID]}
                      </span>
                      <span className="item-total">
                        ${(item.price * cart[item.foodID]).toFixed(2)}
                      </span>
                    </li>
                  ))}
                </ul>
                <div className="cart-total">
                  <span>Total:</span>
                  <span>${calculateTotal()}</span>
                </div>
                <button
                  className="checkout-button"
                  onClick={handleCheckout}
                  whileHover={{ scale: 1.05, backgroundColor: "#d62839" }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  Checkout
                </button>
              </div>
            )}
          </motion.aside>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default RestaurantPage;
