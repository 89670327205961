import React, { useState, useEffect, useRef } from "react";
import { useKitchen } from "../../context/KitchenContext";
import "./KitchenPrice.css";
import { FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const KitchenPrice = ({
  nextStep,
  prevStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const { updateKitchen, updateKitchenEditMode } = useKitchen();
  const [loading, setLoading] = useState(false);

  const [bookingType, setBookingType] = useState(
    kitchenData.bookingType || "hour"
  );
  const [hourlyRate, setHourlyRate] = useState(kitchenData.hourlyRate || "");
  const [dayRate, setDayRate] = useState(kitchenData.dayRate || "");
  const [cleaningFee, setCleaningFee] = useState(kitchenData.cleaningFee || "");
  const [minBooking, setMinBooking] = useState(kitchenData.minBooking || "");
  const [securityDeposit, setSecurityDeposit] = useState(
    kitchenData.securityDeposit || ""
  );
  const [rateEntries, setRateEntries] = useState(
    kitchenData.rateEntries?.length > 0
      ? kitchenData.rateEntries
      : [{ day: "", start: "", end: "", price: "" }]
  );
  const [blockedDates, setBlockedDates] = useState(
    kitchenData.blockedDates || []
  );
  const [newBlockedDate, setNewBlockedDate] = useState(null);

  const isInitialized = useRef(false);
  useEffect(() => {
    if (editMode && kitchenData) {
      setBookingType(kitchenData.bookingType || "hour");
      setHourlyRate(kitchenData.hourlyRate || "");
      setDayRate(kitchenData.dayRate || "");
      setCleaningFee(kitchenData.cleaningFee || "");
      setMinBooking(kitchenData.minBooking || "");
      setSecurityDeposit(kitchenData.securityDeposit || "");
      setRateEntries(kitchenData.rateEntries || []);
      setBlockedDates(kitchenData.blockedDates || []);
    } else if (!editMode) {
      setBookingType("hour");
      setHourlyRate("");
      setDayRate("");
      setCleaningFee("");
      setMinBooking("");
      setSecurityDeposit("");
      setRateEntries([{ day: "", start: "", end: "", price: "" }]);
      setBlockedDates(kitchenData.blockedDates || []);
    }
  }, [editMode, kitchenData]);

  const validateEntry = (entry) => {
    if (!entry.day || !entry.price || parseFloat(entry.price) <= 0) {
      return false;
    }
    if (bookingType === "hour") {
      if (
        entry.start === "" ||
        entry.end === "" ||
        isNaN(entry.start) ||
        isNaN(entry.end)
      ) {
        return false;
      }
      // Allow overnight intervals: no strict check that start < end
      // Just ensure they are not equal.
      if (parseInt(entry.start, 10) === parseInt(entry.end, 10)) {
        return false;
      }
    }
    return true;
  };

  const isOvernight = (entry) => {
    return (
      bookingType === "hour" &&
      parseInt(entry.end, 10) < parseInt(entry.start, 10)
    );
  };

  const checkOverlap = (newEntry, existingEntry) => {
    // Handle overlap logic considering overnight intervals
    const newStart = parseInt(newEntry.start, 10);
    const newEnd = parseInt(newEntry.end, 10);
    const existingStart = parseInt(existingEntry.start, 10);
    const existingEnd = parseInt(existingEntry.end, 10);

    const newIsOvernight = isOvernight(newEntry);
    const existingIsOvernight = isOvernight(existingEntry);

    // Function to check time overlap in a single-day scenario
    const timesOverlap = (s1, e1, s2, e2) => {
      return s1 < e2 && s2 < e1;
    };

    if (newIsOvernight && existingIsOvernight) {
      // Both intervals span midnight
      // Break intervals into two: [newStart,24),[0,newEnd]
      // and [existingStart,24),[0,existingEnd]
      // Check all combinations for overlap
      return (
        timesOverlap(newStart, 24, existingStart, 24) ||
        timesOverlap(newStart, 24, 0, existingEnd) ||
        timesOverlap(0, newEnd, existingStart, 24) ||
        timesOverlap(0, newEnd, 0, existingEnd)
      );
    } else if (newIsOvernight) {
      // New interval crosses midnight
      // Check overlap against existing in two parts
      return (
        timesOverlap(newStart, 24, existingStart, existingEnd) ||
        timesOverlap(0, newEnd, existingStart, existingEnd)
      );
    } else if (existingIsOvernight) {
      // Existing interval crosses midnight
      return (
        timesOverlap(existingStart, 24, newStart, newEnd) ||
        timesOverlap(0, existingEnd, newStart, newEnd)
      );
    } else {
      // Neither is overnight
      return timesOverlap(newStart, newEnd, existingStart, existingEnd);
    }
  };

  const isOverlapping = (newEntry, excludeIndex = null) => {
    if (bookingType === "day") {
      // For daily bookings, no time range overlap check is needed.
      return false;
    }

    return rateEntries.some((entry, index) => {
      if (index === excludeIndex) return false;
      if (entry.day !== newEntry.day) return false;
      return checkOverlap(newEntry, entry);
    });
  };

  const handleAddRateEntry = () => {
    const newEntry =
      bookingType === "hour"
        ? { day: "", start: "", end: "", price: "" }
        : { day: "", price: "" };
    setRateEntries([...rateEntries, newEntry]);
  };

  const handleRemoveRateEntry = (index) => {
    setRateEntries(rateEntries.filter((_, i) => i !== index));
  };

  const handleRateChange = (index, field, value) => {
    const newEntries = [...rateEntries];
    const oldEntry = { ...newEntries[index] };
    const oldValue = oldEntry[field];

    // Update the field with the new value
    newEntries[index][field] = value;
    const updatedEntry = newEntries[index];

    // Determine if the entry is complete based on booking type
    let isComplete = false;
    if (bookingType === "hour") {
      isComplete =
        updatedEntry.day &&
        updatedEntry.price &&
        updatedEntry.start !== "" &&
        updatedEntry.end !== "";
    } else {
      isComplete = updatedEntry.day && updatedEntry.price;
    }

    // Only validate if the entry is complete
    if (isComplete) {
      if (!validateEntry(updatedEntry)) {
        alert(
          "Invalid entry. Make sure you have selected a day, a price greater than 0, and valid start/end times (for hourly bookings)."
        );
        // Revert the change
        newEntries[index][field] = oldValue;
        setRateEntries(newEntries);
        return;
      }

      // If booking by the hour, check for overlapping
      if (bookingType === "hour" && isOverlapping(updatedEntry, index)) {
        alert("Overlapping time slot detected. Please adjust your entries.");
        // Revert the change
        newEntries[index][field] = oldValue;
        setRateEntries(newEntries);
        return;
      }
    }

    // Update the state with the new entries
    setRateEntries(newEntries);
  };

  const handleAddBlockedDate = () => {
    if (!newBlockedDate) {
      alert("Please select a date to block.");
      return;
    }
    const dateStr = newBlockedDate.toISOString().split("T")[0];
    if (blockedDates.includes(dateStr)) {
      alert("This date is already blocked.");
      return;
    }
    setBlockedDates([...blockedDates, dateStr]);
    setNewBlockedDate(null);
  };

  const handleRemoveBlockedDate = (dateStr) => {
    setBlockedDates(blockedDates.filter((d) => d !== dateStr));
  };

  const handleNext = async (e) => {
    e.preventDefault();

    // Final validation
    if (bookingType === "hour" && !hourlyRate) {
      alert("Please provide an hourly rate.");
      return;
    }
    if (bookingType === "day" && !dayRate) {
      alert("Please provide a daily rate.");
      return;
    }
    if (parseFloat(minBooking) <= 0) {
      alert("Minimum booking period must be greater than 0.");
      return;
    }

    for (let i = 0; i < rateEntries.length; i++) {
      if (!validateEntry(rateEntries[i])) {
        alert("Please provide valid details for all rate entries.");
        return;
      }
      if (bookingType === "hour" && isOverlapping(rateEntries[i], i)) {
        alert("Some entries overlap. Please fix them before proceeding.");
        return;
      }
    }

    const pricingData = {
      bookingType,
      hourlyRate,
      dayRate,
      cleaningFee,
      minBooking,
      securityDeposit,
      rateEntries,
      blockedDates,
    };

    const updatedKitchenData = { ...kitchenData, ...pricingData };

    setLoading(true);
    try {
      if (editMode) {
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        await updateKitchen(updatedKitchenData);
      }
      updateKitchenData(pricingData);
      nextStep();
    } catch (error) {
      console.error("Failed to update pricing:", error.message);
      alert("Failed to save pricing details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleNext} className="kitchen-price">
      <h2 className="form-title">
        {editMode ? "Edit Pricing Details" : "Pricing Details"}
      </h2>

      <div className="price-group">
        <div className="price-form-group">
          <label>Booking Type</label>
          <select
            value={bookingType}
            onChange={(e) => {
              setBookingType(e.target.value);
              setRateEntries([]);
            }}
          >
            <option value="hour">Per Hour</option>
            <option value="day">Per Day</option>
          </select>
        </div>

        {bookingType === "hour" ? (
          <div className="price-form-group">
            <label>Hourly Rate</label>
            <input
              type="number"
              placeholder="Enter hourly rate"
              value={hourlyRate}
              onChange={(e) => {
                setHourlyRate(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className="price-form-group">
            <label>Day Rate</label>
            <input
              type="number"
              placeholder="Enter day rate"
              value={dayRate}
              onChange={(e) => {
                setDayRate(e.target.value);
              }}
            />
          </div>
        )}

        <div className="price-form-group">
          {bookingType === "hour" ? (
            <>
              <label>Minimum Booking Hours</label>
              <input
                type="number"
                placeholder="Enter minimum hours"
                value={minBooking}
                onChange={(e) => {
                  setMinBooking(e.target.value);
                }}
              />
              <small className="field-note">
                Note: We recommend a minimum of 3 hours.
              </small>
            </>
          ) : (
            <>
              <label>Minimum Booking Days</label>
              <input
                type="number"
                placeholder="Enter minimum days"
                value={minBooking}
                onChange={(e) => {
                  setMinBooking(e.target.value);
                }}
              />
              <small className="field-note">
                Note: We recommend a minimum of 1 day.
              </small>
            </>
          )}
        </div>
      </div>

      <h2 className="price-title">Fees</h2>
      <div className="form-row">
        <div className="price-form-group">
          <label>Cleaning Fees ($)</label>
          <input
            type="number"
            placeholder="Enter cleaning fee"
            value={cleaningFee}
            onChange={(e) => {
              setCleaningFee(e.target.value);
            }}
          />
        </div>

        <div className="price-form-group">
          <label>Security Deposit ($)</label>
          <input
            type="number"
            placeholder="Enter security deposit"
            value={securityDeposit}
            onChange={(e) => {
              setSecurityDeposit(e.target.value);
            }}
          />
          <small className="field-note">
            Note: We recommend adjusting this for longer bookings.
          </small>
        </div>
      </div>

      {bookingType === "hour" ? (
        <h2 className="price-title">Set Hourly Availability</h2>
      ) : (
        <h2 className="price-title">Set Daily Rates</h2>
      )}
      <small className="info-note">
        Specify the availability of your kitchen. This information will be used
        to determine and display the rental hours for your kitchen.
      </small>

      <div className="rate-entries">
        {rateEntries.map((entry, index) => (
          <div className="rate-entry" key={index}>
            <select
              value={entry.day}
              onChange={(e) => handleRateChange(index, "day", e.target.value)}
            >
              <option value="">Select Day</option>
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
              <option value="sunday">Sunday</option>
            </select>

            {bookingType === "hour" && (
              <>
                <select
                  value={entry.start}
                  onChange={(e) =>
                    handleRateChange(index, "start", e.target.value)
                  }
                >
                  <option value="">Start Time</option>
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i.toString().padStart(2, "0")}:00
                    </option>
                  ))}
                </select>

                <select
                  value={entry.end}
                  onChange={(e) =>
                    handleRateChange(index, "end", e.target.value)
                  }
                >
                  <option value="">End Time</option>
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i.toString().padStart(2, "0")}:00
                    </option>
                  ))}
                </select>
              </>
            )}

            <input
              type="number"
              placeholder="Price"
              value={entry.price}
              onChange={(e) => handleRateChange(index, "price", e.target.value)}
            />

            <FaTimes
              className="remove-entry-icon"
              onClick={() => handleRemoveRateEntry(index)}
            />
          </div>
        ))}
        <div className="entry-btn-container">
          <button
            type="button"
            className="add-entry-btn"
            onClick={handleAddRateEntry}
          >
            Add Entry
          </button>
        </div>
      </div>

      <h2 className="price-title">Blocked Dates</h2>
      <small className="info-note">
        You can block certain dates to make the kitchen unavailable for booking
        on specific dates.
      </small>
      <div className="blocked-dates-container">
        <div className="blocked-date-picker">
          <DatePicker
            selected={newBlockedDate}
            onChange={(date) => setNewBlockedDate(date)}
            minDate={new Date()}
            placeholderText="Select a date to block"
          />
          <button
            className="add-entry-btn"
            type="button"
            onClick={handleAddBlockedDate}
          >
            Block Date
          </button>
        </div>
        {blockedDates.length > 0 && (
          <ul className="blocked-date-list">
            {blockedDates.map((dateStr, idx) => (
              <li key={idx}>
                {dateStr}{" "}
                <FaTimes
                  className="remove-blocked-date-icon"
                  onClick={() => handleRemoveBlockedDate(dateStr)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="navigation-buttons">
        <button type="button" onClick={prevStep} className="prev-btn">
          Previous
        </button>
        <button type="submit" className="next-btn" disabled={loading}>
          {loading ? "Saving..." : editMode ? "Update" : "Next"}
        </button>
      </div>
    </form>
  );
};

export default KitchenPrice;
