import React from "react";
import { Link } from "react-router-dom";
import "./RestaurantCard.css";

const RestaurantCard = ({ restaurant }) => {
  const { name, location, min_order, delivery_fee, restaurantID, images } =
    restaurant;

  const defaultImage =
    "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/assets/RestaurantComingSoon.png";

  return (
    <Link
      to={`/restaurant/${name}/${restaurantID}`}
      className="restaurant-card-link"
    >
      <div className="restaurant-card">
        {/* Image Container */}
        <div className="restaurant-image-container">
          <img
            src={images && images.length > 0 ? images[0] : defaultImage}
            alt={name || "Restaurant"}
            className="restaurant-image"
          />
        </div>

        {/* Info Section */}
        <div className="restaurant-info">
          <h3 className="restaurant-name">{name}</h3>
          <p className="restaurant-min-order">{location}</p>
          <p className="restaurant-min-order">Min Order: ${min_order}</p>
          {/* <p className="restaurant-delivery-fee">Delivery Fee: ${delivery_fee}</p> */}
        </div>
      </div>
    </Link>
  );
};

export default RestaurantCard;
