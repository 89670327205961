import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import config from "../config";

// Created Auth context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  // Check if the user is logged in on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("token");

    if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
    }
  }, []);

  // Fetch user details from backend using token and update AuthContext
  const fetchUserProfile = async (userID) => {
    console.log("[AuthContext] Initiating fetchUserProfile for user");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${config.apiBaseUrl}/api/auth/user/${userID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUser((prevUser) => ({
        ...response.data,
        role: response.data.role || prevUser.role,
      }));
      localStorage.setItem("user", JSON.stringify(response.data));
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      logout();
    }
  };

  // Function to login and save data
  const login = async (credentials, redirectPath = "/") => {
    console.log("[AuthContext] User logging in.");
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/auth/login`,
        credentials
      );
      const { email, userID, token } = response.data;

      setUser({ email, userID });
      setToken(token);
      localStorage.setItem("user", JSON.stringify({ email, userID }));
      localStorage.setItem("token", token);

      if (userID) {
        await fetchUserProfile(userID);
      } else {
        console.error("UserID is missing after login");
      }
    } catch (error) {
      console.error("[AuthContext] Login failed:", error);
      throw error;
    }
  };

  // Function to logout and clear data
  const logout = () => {
    console.log("[AuthContext] User logged out.");
    setUser(null);
    setToken(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider
      value={{ user, token, login, logout, fetchUserProfile }}
    >
      {children}
    </AuthContext.Provider>
  );
};
