import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { FaSignOutAlt, FaChevronDown } from "react-icons/fa";
import dashboard from "../../assets/images/hugeicons_dashboard-square-edit.svg";
import myKitchen from "../../assets/images/My Kitchenz Icon.svg";
import My_Food_Item from "../../assets/images/food-item.svg";
import addNewKitchen from "../../assets/images/AddNewKitchenIcon.svg";
import booking from "../../assets/images/Booking.svg";
import reservation from "../../assets/images/BookingIcon.svg";
import calender from "../../assets/images/Calendericon.svg";
import settings from "../../assets/images/Setting Icon.svg";
import admin from "../../assets/images/user-admin.svg";
import Sign_Out from "../../assets/images/SignOut.svg";
import "./UserMenuTabs.css";
import messages from "../../assets/images/profile-messages.svg";

const UserMenuTabs = () => {
  const { user, logout } = useContext(AuthContext);

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Menu");
  const toggleMenu = () => setIsOpen(!isOpen);

  const handleSelect = (optionText) => {
    setSelectedOption(optionText);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsOpen(false);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="user-menu-tabs">
      {isMobile ? (
        <>
          <button className="menu-toggle" onClick={toggleMenu}>
            <a className="menu-icon" /> {selectedOption}{" "}
            <FaChevronDown className={`arrow-icon ${isOpen ? "open" : ""}`} />
          </button>
          {isOpen && (
            <ul className={`dropdown-menu ${isOpen ? "show" : ""}`}>
              <li>
                <NavLink
                  to="/profile"
                  end
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("My Profile")}
                >
                  <img src={dashboard} alt="Dashboard" className="icon" /> My
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/messages"
                  end
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("Messages")}
                >
                  <img src={messages} alt="Messages" className="icon" />
                  Messages
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/my-kitchens"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("My Kitchens")}
                >
                  <img src={myKitchen} alt="My Kitchens" className="icon" /> My
                  Kitchens
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/add-kitchen"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("Add New Kitchen")}
                >
                  <img
                    src={addNewKitchen}
                    alt="Add New Kitchen"
                    className="icon"
                  />{" "}
                  Add New Kitchen
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/my-food-item"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("My Food Item")}
                >
                  <img src={My_Food_Item} alt="My Food Items" className="icon" /> My
                  Food Item
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/add-food-item"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("Add Food Item")}
                >
                  <img
                    src={addNewKitchen}
                    alt="Add New Kitchen"
                    className="icon"
                  />{" "}
                  Add Food Item
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/my-restaurants"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("My Restaurants")}
                >
                  <img src={myKitchen} alt="My Restaurants" className="icon" /> My
                  Restaurants
                </NavLink>
              </li>
              <li>
              <NavLink
                to="/profile/add-restaurant"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img
                  src={addNewKitchen}
                  alt="Add New Restaurant"
                  className="icon"
                />{" "}
                Add New Restaurant
              </NavLink>
            </li>
              <li>
                <NavLink
                  to="/profile/my-bookings"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("My Bookings")}
                >
                  <img
                    src={booking}
                    alt="Booking"
                    className="icon"
                    onClick={toggleMenu}
                  />{" "}
                  My Bookings
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/my-reservations"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("My Reservations")}
                >
                  <img
                    src={reservation}
                    alt="Reservations"
                    className="icon"
                    onClick={toggleMenu}
                  />{" "}
                  My Reservations
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/calendar"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("Calandar")}
                >
                  <img
                    src={calender}
                    alt="Calendar"
                    className="icon"
                    onClick={toggleMenu}
                  />{" "}
                  Calendar
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile/settings"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("Settings")}
                >
                  <img
                    src={settings}
                    alt="Settings"
                    className="icon"
                    onClick={toggleMenu}
                  />{" "}
                  Settings
                </NavLink>
              </li>
              {user?.role === "admin" && (
                <li>
                  <NavLink
                    to="/admin"
                    className={({ isActive }) =>
                      `menu-link ${isActive ? "active" : ""}`
                    }
                    onClick={() => handleSelect("Admin Panel")}
                  >
                    <img src={admin} alt="Admin Panel" className="icon" />
                    Admin Panel
                  </NavLink>
                </li>
              )}
              {user && (
                <li>
                  <NavLink onClick={logout} className="logout-button">
                    <img src={Sign_Out} className="Sign Out" onClick={toggleMenu} />
                    Sign Out
                  </NavLink>
                </li>
              )}
            </ul>
          )}
        </>
      ) : (
        <div>
          <ul>
            <li>
              <NavLink
                to="/profile"
                end
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img src={dashboard} alt="Dashboard" className="icon" /> My
                Profile
              </NavLink>
            </li>
            <li>
                <NavLink
                  to="/profile/messages"
                  end
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("Messages")}
                >
                  <img src={messages} alt="Messages" className="icon" />
                  Messages
                </NavLink>
              </li>
            <li>
              <NavLink
                to="/profile/my-kitchens"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img src={myKitchen} alt="My Kitchens" className="icon" /> My
                Kitchens
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile/add-kitchen"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img
                  src={addNewKitchen}
                  alt="Add New Kitchen"
                  className="icon"
                />{" "}
                Add New Kitchen
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile/my-food-item"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img src={My_Food_Item} alt="My Kitchens" className="icon" /> My
                Food Item
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile/add-food-item"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img
                  src={addNewKitchen}
                  alt="Add New Kitchen"
                  className="icon"
                />{" "}
                Add New Food Item
              </NavLink>
            </li>
            <li>
                <NavLink
                  to="/profile/my-restaurants"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleSelect("My Restaurants")}
                >
                  <img src={myKitchen} alt="My Restaurants" className="icon" /> My
                  Restaurants
                </NavLink>
              </li>
            <li>
              <NavLink
                to="/profile/add-restaurant"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img
                  src={addNewKitchen}
                  alt="Add New Restaurant"
                  className="icon"
                />{" "}
                Add New Restaurant
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile/my-bookings"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img src={booking} alt="Booking" className="icon" /> My Bookings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile/my-reservations"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img src={reservation} alt="Reservations" className="icon" /> My
                Reservations
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile/calendar"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img src={calender} alt="Calendar" className="icon" /> Calendar
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile/settings"
                className={({ isActive }) =>
                  `menu-link ${isActive ? "active" : ""}`
                }
              >
                <img src={settings} alt="Settings" className="icon" /> Settings
              </NavLink>
            </li>
            {user?.role === "admin" && (
              <li>
                <NavLink
                  to="/admin"
                  className={({ isActive }) =>
                    `menu-link ${isActive ? "active" : ""}`
                  }
                >
                  <img src={admin} alt="Admin Panel" className="icon" />
                  Admin Panel
                </NavLink>
              </li>
            )}
            {user && (
              <li>
                <NavLink onClick={logout} className="logout-button">
                  <img src={Sign_Out} alt="Sign Out" className="icon" />
                    Sign Out
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserMenuTabs;
