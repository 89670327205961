import React, { useEffect, useState, useContext } from "react";
import { useFood } from "../../context/FoodContext";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./AddFoodItem.css";
import axios from "axios";

const AddFoodItem = ({ editMode }) => {
  const { foodID } = useParams();
  const { user } = useContext(AuthContext);
  const { createFoodItem, updateFoodItem, fetchAllFoodItems, fetchRestaurants } = useFood();
  const [foodData, setFoodData] = useState({
    foodID: "",
    name: "",
    category: "",
    ingredients: "",
    price: "",
    size: "",
    serves: "",
    restaurantID: "",
    userID: "",
  });
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    if (user && user.userID) {
        const fetchRestaurantsData = async () => {
        console.log("Fetching restaurants for user ID:", user.userID);
        const restaurantNames = await fetchRestaurants(user.userID);
        setRestaurants(restaurantNames);
        };

        fetchRestaurantsData();
    }
  }, [user]);

  useEffect(() => {
    if (editMode && foodID) {
      const fetchFoodData = async () => {
        try {
          const response = await axios.get(`/api/food-items/${foodID}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.status === 200) {
            setFoodData(response.data);
          } else {
            throw new Error("Failed to fetch food data");
          }
        } catch (error) {
          console.error("Error fetching food data:", error);
          alert("Failed to fetch food data. Please try again.");
        }
      };
      fetchFoodData();
    }
  }, [editMode, foodID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFoodData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const foodItemData = { 
        ...foodData, 
        restaurantID: foodData.restaurantID,
        userID: user.userID
      };
      if (editMode) {
        await updateFoodItem(foodID, foodItemData);
        alert("Food item updated successfully!");
      } else if (user.userID) {
        await createFoodItem(foodItemData);
        alert("Food item added successfully!");
      } else {
        alert("UserID is required.");
      }
      fetchAllFoodItems({ userID: user.userID });
    } catch (error) {
      console.error("Error saving food item:", error);
      alert("Failed to save food item. Please try again.");
    }
  };

  return (
    <div className="add-food-item">
      <h1>{editMode ? "Edit Food Item" : "Add New Food Item"}</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={foodData.name}
          onChange={handleChange}
          placeholder="Food Name"
          required
        />
        <input
          type="text"
          name="category"
          value={foodData.category}
          onChange={handleChange}
          placeholder="Category"
          required
        />
        <textarea
          name="ingredients"
          value={foodData.ingredients}
          onChange={handleChange}
          placeholder="Ingredients"
          required
        />
        <input
          type="number"
          name="price"
          value={foodData.price}
          onChange={handleChange}
          placeholder="Price"
          required
        />
        <input
          type="text"
          name="size"
          value={foodData.size}
          onChange={handleChange}
          placeholder="Size"
          required
        />
        <input
          type="number"
          name="serves"
          value={foodData.serves}
          onChange={handleChange}
          placeholder="Serves"
          required
        />
        <select
          name="restaurantID"
          value={foodData.restaurantID}
          onChange={handleChange}
          required
        >
          <option value="">Select Restaurant</option>
          {restaurants.map((restaurantName, index) => (
            <option key={index} value={restaurantName}>
              {restaurantName}
            </option>
          ))}
        </select>
        <button type="submit">{editMode ? "Update" : "Add"}</button>
      </form>
    </div>
  );
};

export default AddFoodItem;
