import React, { useState } from "react";
import axios from "axios";
import "./RestaurantImages.css";

const RestaurantImages = ({
  prevStep,
  nextStep,
  restaurantData,
  updateRestaurantData,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });
    formData.append("restaurantID", restaurantData.restaurantID);

    try {
      const response = await axios.post(
        "/api/restaurants/upload-images",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Uploaded image URLs:", response.data.imageUrls);
      updateRestaurantData({ images: response.data.imageUrls });
      nextStep();
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  return (
    <div className="restaurant-images">
      <h2>Restaurant Images</h2>
      <label>Upload Images:</label>
      <input type="file" multiple onChange={handleFileChange} />
      <div className="restaurant-navigation-buttons">
        <button onClick={prevStep} className="prev-btn">
          Back
        </button>
        <button onClick={handleUpload} className="next-btn">
          Next
        </button>
      </div>
    </div>
  );
};

export default RestaurantImages;
