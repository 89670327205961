// KitchenAmenities.js

import React, { useState, useRef, useEffect } from "react";
import { useKitchen } from "../../context/KitchenContext";
import "./KitchenAmenities.css";

// Define the categories and their respective amenities
const AMENITY_CATEGORIES = {
  Amenities: [
    { name: "parking", label: "Parking Facilities" },
    { name: "dineIn", label: "Dine-In Facilities" },
    { name: "storageSpace", label: "Storage Space" },
    // Add other amenities under this category
  ],
  "Cooking Equipment": [
    { name: "charbroiler", label: "Charbroiler" },
    { name: "convectionOven", label: "Convection Oven" },
    { name: "commercialOven1", label: "Commercial Oven (Single)" },
    { name: "commercialOven2", label: "Commercial Oven (Double)" },
    { name: "deepFryer", label: "Deep Fryer" },
    { name: "griddle", label: "Griddle" },
    { name: "pizzaOven", label: "Pizza Oven" },
    { name: "microwave", label: "Microwave" },
    { name: "steamKettle", label: "Steam Kettle" },
    { name: "rangeStove10Burners", label: "Range/Stove with 10 Burners" },
    { name: "rangeStove6Burners", label: "Range/Stove with 6 Burners" },
    { name: "rangeStove4Burners", label: "Range/Stove with 4 Burners" },
    // Add other cooking equipment
  ],
  "Dishwashing and Cleaning": [
    { name: "cleaningSupplies", label: "Cleaning Supplies" },
    { name: "dishDryingRacks", label: "Dish Drying Racks" },
    { name: "dishwasher", label: "Dishwasher (commercial)" },
    { name: "prepSinks", label: "Prep Sinks" },
    { name: "sink2compartment", label: "Sinks (with 2 compartments)" },
    { name: "sink3compartment", label: "Sinks (with 3 compartments)" },
    // Add other dishwashing and cleaning amenities
  ],
  Facilities: [
    { name: "basicAppliances", label: "Basic Appliances" },
    { name: "fullyEquiped", label: "Fully Equipped" },
    { name: "specializedEquipment", label: "Specialized Equipment (e.g industrial mixtures)" },
    // Add other facilities
  ],
  "Food Preparation": [
    { name: "blender", label: "Blender" },
    { name: "foodProcessor", label: "Food Processor" },
    { name: "mixer", label: "Mixer (Stand or Hand)" },
    { name: "foodScales", label: "Food Scales" },
    { name: "immersionCirculator", label: "Immersion Circulator (sous vide)" },
    { name: "slicer", label: "Slicer (meat and vegetable)" },
    { name: "vaccumSealer", label: "Vacuum Sealer" },
    // Add other food preparation amenities
  ],
  Nearby: [
    { name: "banks", label: "Banks" },
    { name: "eventVenue", label: "Event Venue" },
    { name: "freeway", label: "Freeway" },
    { name: "groceryStore", label: "Grocery Store" },
    { name: "hospitalBuilding", label: "Hospital or Medical Building" },
    { name: "officeBuilding", label: "Office Buildings" },
    { name: "publicParking", label: "Public Parking" },
    { name: "sportsVenue", label: "Sports Venue" },
    // Add other nearby amenities
  ],
  "Refrigeration and Cooling": [
    { name: "commercialRefrigerator", label: "Commercial Refrigerator" },
    { name: "displayRefrigerator", label: "Display Refrigerator (for showcasing products)" },
    { name: "freezer", label: "Freezer" },
    { name: "iceMachine", label: "Ice Machine" },
    { name: "walkInCooler", label: "Walk-in Cooler" },
    // Add other refrigeration and cooling amenities
  ],
  "Safety and Miscellaneous": [
    { name: "fireExtinguisher", label: "Fire Extinguisher" },
    { name: "firstAidKit", label: "First Aid Kit" },
    { name: "greaseTrap", label: "Grease Trap" },
    { name: "hoodSystem", label: "Hood System" },
    { name: "safetySignage", label: "Safety Signage (e.g., 'Wet floor' signs)" },
    { name: "trashCans", label: "Trash Cans and Recycling Bins" },
    { name: "ventilationSystem", label: "Ventilation System" },
    // Add other safety and miscellaneous amenities
  ],
  "Service and Display": [
    { name: "buffetLineEquipment", label: "Buffet Line Equipment (e.g., chafing dishes)" },
    { name: "displayCases", label: "Display Cases" },
    { name: "servingUtensils", label: "Serving Utensils" },
    { name: "plateWarmers", label: "Plate Warmers" },
    { name: "warmingDrawers", label: "Warming Drawers" },
    // Add other service and display amenities
  ],
  "Smallwares and Tools": [
    { name: "bakingSheets", label: "Baking Sheets and Pans" },
    { name: "cookingUtensils", label: "Cooking Utensils (spatulas, ladles, tongs, etc.)" },
    { name: "cuttingBoards", label: "Cutting Boards (various sizes)" },
    { name: "kitchenTimers", label: "Kitchen Timers" },
    { name: "mixingBowls", label: "Mixing Bowls (various sizes)" },
    { name: "foodStorageContainers", label: "Food Storage Containers" },
    { name: "thermometers", label: "Thermometers (food and oven)" },
    { name: "potsAndPans", label: "Pots and Pans (various sizes)" },
    // Add other smallwares and tools
  ],
  "Storage and Organization": [
    { name: "ingredientBins", label: "Ingredient Bins" },
    { name: "labelingEquipment", label: "Labeling Equipment (e.g., label printer)" },
    { name: "prepTables", label: "Prep Tables (stainless steel)" },
    { name: "shelvingUnits", label: "Shelving Units" },
    { name: "storageRacks", label: "Storage Racks" },
    // Add other storage and organization amenities
  ],
  "Usage Type": [
    { name: "catering", label: "Catering" },
    { name: "deliveryOnlyRestaurant", label: "Delivery Only Restaurant" },
    { name: "foodProduction", label: "Food Production" },
    { name: "cookingClasses", label: "Cooking Classes" },
    { name: "cookingWorkshops", label: "Cooking Workshops" },
    { name: "foodPhotoshoots", label: "Food Photoshoots" },
    { name: "popUpRestaurants", label: "Pop-up Restaurants" },
    { name: "foodPreparation", label: "Food Preparation" },
    // Add other usage types
  ],
};

const KitchenAmenities = ({
  nextStep,
  prevStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const { updateKitchen, updateKitchenEditMode } = useKitchen();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Initialize state with all amenities set to false
  const initialAmenitiesState = useRef({});
  const [amenities, setAmenities] = useState({});

  useEffect(() => {
    if (Object.keys(initialAmenitiesState.current).length === 0) {
      // Initialize initialAmenitiesState only once
      initialAmenitiesState.current = Object.values(AMENITY_CATEGORIES)
        .flat()
        .reduce((acc, amenity) => {
          acc[amenity.name] = false;
          return acc;
        }, {});

      if (kitchenData && kitchenData.amenities) {
        // If in edit mode, set amenities from kitchenData
        Object.entries(kitchenData.amenities).forEach(([category, amenitiesList]) => {
          amenitiesList.forEach((amenityLabel) => {
            const amenityEntry = Object.values(AMENITY_CATEGORIES)
              .flat()
              .find((amenity) => amenity.label === amenityLabel);
            if (amenityEntry) {
              initialAmenitiesState.current[amenityEntry.name] = true;
            }
          });
        });
      }

      setAmenities(initialAmenitiesState.current);
    }
  }, [kitchenData]);


  const handleAmenityChange = (e) => {
    const { name, checked } = e.target;
    setAmenities({ ...amenities, [name]: checked });
    updateKitchenData({
      amenities: {
        ...kitchenData.amenities,
        [name]: checked,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Structure the selected amenities by category
    const structuredAmenities = {};

    Object.keys(AMENITY_CATEGORIES).forEach((category) => {
      const selected = AMENITY_CATEGORIES[category]
        .filter((amenity) => amenities[amenity.name])
        .map((amenity) => amenity.label);

      if (selected.length > 0) {
        structuredAmenities[category] = selected;
      }
    });

    const amenitiesData = { amenities: structuredAmenities };

    // Merge with existing kitchenData
    const updatedKitchenData = { ...kitchenData, ...amenitiesData };

    try {
      if (editMode) {
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        await updateKitchen(updatedKitchenData);
      }
      // Update central kitchenData
      updateKitchenData(amenitiesData);

      setShowModal(true);
    } catch (error) {
      console.error("Failed to update amenities details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.href = "/profile/add-kitchen";
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="kitchen-amenities">
        <h2 className="form-title">{editMode ? "Edit Amenities and Features" : "Amenities and Features"}</h2>

        {Object.entries(AMENITY_CATEGORIES).map(([category, amenitiesList]) => (
          <div className="amenity-category" key={category}>
            <h3>{category}</h3>
            <div className="form-group">
              {amenitiesList.map((amenity) => (
                <label key={amenity.name}>
                  <input
                    type="checkbox"
                    name={amenity.name}
                    checked={amenities[amenity.name] || false}
                    onChange={handleAmenityChange}
                  />
                  {amenity.label}
                </label>
              ))}
            </div>
          </div>
        ))}

        <div className="navigation-buttons">
          <button type="button" onClick={prevStep} className="prev-btn">
            Previous
          </button>
          <button type="submit" className="next-btn">
            {loading ? "Saving..." : editMode ? "Update" : "Submit"}
          </button>
          {loading && (
            <div className="loading-indicator">
              {editMode
                ? "Updating your amenities details..."
                : "Saving your amenities details..."}
            </div>
          )}
        </div>
      </form>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={closeModal}>
              ×
            </button>
            <h3>Success!</h3>
            <p>
              Your kitchen has been {editMode ? "updated" : "successfully added"}.
            </p>
            <p>
              You can make edits and changes by navigating to the{" "}
              <a href="/profile/my-kitchens" className="my-kitchen-link">
                My Kitchens
              </a>{" "}
              section in your profile.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default KitchenAmenities;
