// src/components/MyProfile/Messages.js
import React, { useEffect, useState } from "react";
import axios from "axios";
// If you created an axios config file, import it instead
// import axiosInstance from "../../utils/axiosConfig";

const Messages = ({ user }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  // Example: fetch user conversations on mount
  useEffect(() => {
    if (!user?.userID) return;
    axios
      .get(`/api/conversations/${user.userID}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setConversations(res.data.conversations || []);
      })
      .catch((err) => console.error("Error fetching conversations", err));
  }, [user]);

  // Fetch messages for a conversation
  const fetchMessages = (conversation) => {
    setSelectedConversation(conversation);
    axios
      .get(`/api/messages/${conversation.conversationId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setMessages(res.data.messages || []);
      })
      .catch((err) => console.error("Error fetching messages", err));
  };

  // Send a new message
  const handleSendMessage = () => {
    if (!selectedConversation) return;

    const payload = {
      senderId: user.userID,
      receiverId: selectedConversation.otherUserId,
      messageBody: newMessage,
      conversationId: selectedConversation.conversationId,
    };

    axios
      .post("/api/messages/send", payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(() => {
        setMessages([
          ...messages,
          {
            ...payload,
            timestamp: new Date().toISOString(),
            isRead: false,
          },
        ]);
        setNewMessage("");
      })
      .catch((err) => console.error("Error sending message", err));
  };

  return (
    <div style={{ padding: "1rem" }}>
      <h3>Messages</h3>
      <div style={{ display: "flex", gap: "1rem" }}>
        {/* Conversations List */}
        <div style={{ flex: "1", borderRight: "1px solid #ccc" }}>
          <h4>Your Conversations</h4>
          {conversations.length === 0 ? (
            <p>No conversations yet.</p>
          ) : (
            conversations.map((conv) => (
              <div
                key={conv.conversationId}
                onClick={() => fetchMessages(conv)}
                style={{
                  padding: ".5rem",
                  cursor: "pointer",
                  backgroundColor:
                    selectedConversation?.conversationId === conv.conversationId
                      ? "#f0f0f0"
                      : "transparent",
                }}
              >
                Conversation with {conv.otherUserName}
              </div>
            ))
          )}
        </div>

        {/* Messages Area */}
        <div style={{ flex: "2", display: "flex", flexDirection: "column" }}>
          {selectedConversation ? (
            <>
              <h4>Chat</h4>
              <div
                style={{
                  flex: "1",
                  border: "1px solid #ccc",
                  padding: ".5rem",
                  marginBottom: ".5rem",
                  overflowY: "auto",
                  maxHeight: "300px",
                }}
              >
                {messages.map((msg, idx) => (
                  <div
                    key={idx}
                    style={{
                      textAlign:
                        msg.senderId === user.userID ? "right" : "left",
                      margin: "0.3rem",
                    }}
                  >
                    <p
                      style={{
                        display: "inline-block",
                        padding: ".5rem 1rem",
                        borderRadius: "8px",
                        backgroundColor:
                          msg.senderId === user.userID
                            ? "#DCF8C6"
                            : "#E6E6E6",
                      }}
                    >
                      {msg.messageBody}
                    </p>
                  </div>
                ))}
              </div>
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  style={{ flex: "1", marginRight: ".5rem" }}
                />
                <button onClick={handleSendMessage}>Send</button>
              </div>
            </>
          ) : (
            <p>Please select a conversation.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
