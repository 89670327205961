import React, { useEffect } from "react";
import { useFood } from "../../context/FoodContext";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { IoMdArchive } from "react-icons/io";
import "./MyFoodItems.css";

const MyFoodItems = () => {
  const { allFoodItems, setAllFoodItems, user, fetchFoodByUser } = useFood();

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.userID) {
        try {
          const items = await fetchFoodByUser(user.userID);
          setAllFoodItems(items)
          console.log(items);
        } catch (error) {
          console.error("Error fetching food items:", error);
        }
      }
    };
    fetchData();
  }, [setAllFoodItems, user]);

  const handleDelete = async (foodID) => {
    try {
      // Call delete API for food item
      await fetch(`/api/food/delete/${foodID}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      alert("Food item deleted successfully.");
      setAllFoodItems((prevItems) => prevItems.filter((item) => item.foodID !== foodID));
    } catch (error) {
      console.error("Error deleting food item:", error);
      alert("Failed to delete food item.");
    }
  };

  if (!user || !user.userID) {
    return <p className="loading">Please log in or sign up to view your food items.</p>;
  }

  if (!allFoodItems || allFoodItems.length === 0) {
    return <p className="loading">No food items available.</p>;
  }

  return (
    <div className="my-food-items-container">
      <h1>MY FOOD ITEMS</h1>
      <ul className="food-items-list">
        {allFoodItems.map((item) => (
          <li key={item.foodID} className="food-item">
            <div className="food-item-details">
              <h3>{item.name}</h3>
              <p>Category: {item.category}</p>
              <p>Ingredients: {item.ingredients}</p>
              <p>Price: ${item.price}</p>
              <p>Size: {item.size}</p>
              <p>Serves: {item.serves} people</p>
            </div>
            <div className="food-item-actions">
              <Link to={`/profile/edit-food-item/${item.foodID}`} className="edit-btn">
                <FaEdit /> Edit
              </Link>
              <button
                className="delete-btn"
                onClick={() => handleDelete(item.foodID)}
              >
                <IoMdArchive /> Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyFoodItems;
