import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";

const KitchensTable = () => {
  const [kitchens, setKitchens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingKitchen, setEditingKitchen] = useState(null);

  useEffect(() => {
    const fetchKitchens = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found. Please log in.");
        }

        const response = await axios.get(
          `${config.apiBaseUrl}/api/admin/kitchens`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setKitchens(response.data);
      } catch (error) {
        console.error("Error fetching kitchens:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchKitchens();
  }, []);

  const handleDelete = async (kitchenID) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found. Please log in.");
      }

      await axios.delete(
        `${config.apiBaseUrl}/api/admin/kitchens/${kitchenID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setKitchens((prevKitchens) =>
        prevKitchens.filter((kitchen) => kitchen.KitchenID !== kitchenID)
      );
    } catch (error) {
      console.error("Error deleting kitchen:", error);
    }
  };

  const handleEdit = (kitchen) => {
    setEditingKitchen(kitchen);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found. Please log in.");
      }

      const { KitchenID, ...updatedData } = editingKitchen;

      const response = await axios.put(
        `${config.apiBaseUrl}/api/admin/kitchens/${KitchenID}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setKitchens((prevKitchens) =>
        prevKitchens.map((kitchen) =>
          kitchen.KitchenID === KitchenID ? response.data : kitchen
        )
      );

      setEditingKitchen(null); // Close the modal after saving
    } catch (error) {
      console.error("Error saving kitchen:", error);
    }
  };

  const handleStatusChange = async (kitchenID, newStatus) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found. Please log in.");

      await axios.put(
        `${config.apiBaseUrl}/api/admin/kitchens/${kitchenID}/status`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update local state to reflect the change
      setKitchens((prevKitchens) =>
        prevKitchens.map((kitchen) =>
          kitchen.KitchenID === kitchenID
            ? { ...kitchen, KitchenStatus: newStatus }
            : kitchen
        )
      );
    } catch (error) {
      console.error("Error updating kitchen status:", error);
    }
  };

  const handleViewDetails = (kitchenID) => {
    const newTabURL = `/kitchen/${kitchenID}`;
    window.open(newTabURL, "_blank");
  };

  return (
    <div className="kitchens-table">
      <h2>Kitchens</h2>
      {loading ? (
        <p>Loading kitchens...</p>
      ) : (
        <div className="kitchens-table-container">
          <table className="kitchens-table-content">
            <thead>
              <tr>
                <th>Title</th>
                <th>Category</th>
                <th>Size</th>
                <th>City</th>
                {/* <th>Neighborhood</th> */}
                <th>Booking Type</th>
                <th>Min Booking</th>
                <th>Hourly Rate</th>
                <th>Day Rate</th>
                <th>Cleaning Fee</th>
                <th>Security Deposit</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {kitchens.map((kitchen) => (
                <tr key={kitchen.KitchenID}>
                  <td>{kitchen.Title}</td>
                  <td>{kitchen.Category}</td>
                  <td>{kitchen.Size}</td>
                  <td>{kitchen.City}</td>
                  {/* <td>{kitchen.Neighborhood}</td> */}
                  <td>{kitchen.BookingType}</td>
                  <td>{kitchen.MinBooking}</td>
                  <td>{kitchen.HourlyRate}</td>
                  <td>{kitchen.DayRate}</td>
                  <td>{kitchen.CleaningFee}</td>
                  <td>{kitchen.SecurityDeposit}</td>
                  <td>
                    <select
                      value={kitchen.KitchenStatus}
                      onChange={(e) =>
                        handleStatusChange(kitchen.KitchenID, e.target.value)
                      }
                    >
                      <option value="draft">Draft</option>
                      <option value="approve">Approve</option>
                      <option value="archive">Archive</option>
                    </select>
                  </td>
                  <td>
                    <button
                      onClick={() => handleEdit(kitchen)}
                      className="action-button"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(kitchen.KitchenID)}
                      className="action-button"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => handleViewDetails(kitchen.KitchenID)}
                      className="action-button"
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {editingKitchen && (
        <div className="admin-modal">
          <div className="admin-modal-content">
            <h3>Edit Kitchen</h3>
            <label>
              Title:
              <input
                type="text"
                value={editingKitchen.Title}
                onChange={(e) =>
                  setEditingKitchen({
                    ...editingKitchen,
                    Title: e.target.value,
                  })
                }
              />
            </label>
            <label>
              City:
              <input
                type="text"
                value={editingKitchen.City}
                onChange={(e) =>
                  setEditingKitchen({
                    ...editingKitchen,
                    City: e.target.value,
                  })
                }
              />
            </label>
            <label>
              Hourly Rate:
              <input
                type="number"
                value={editingKitchen.HourlyRate}
                onChange={(e) =>
                  setEditingKitchen({
                    ...editingKitchen,
                    HourlyRate: parseFloat(e.target.value),
                  })
                }
              />
            </label>
            <button onClick={handleSave}>Save</button>
            <button onClick={() => setEditingKitchen(null)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KitchensTable;
