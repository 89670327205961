import React, { useState, useEffect } from "react";
import axios from "axios";
import { useKitchen } from "../../context/KitchenContext";
import "./KitchenDocuments.css";
import config from "../../config";

const KitchenDocuments = ({
  nextStep,
  prevStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const { updateKitchen, updateKitchenEditMode } = useKitchen();
  const [loading, setLoading] = useState(false);

  // We'll keep 3 separate pieces of state, one for each required doc
  const [liabilityInsurance, setLiabilityInsurance] = useState({
    file: null,
    preview: "",
    url: "",
  });
  const [healthCertificate, setHealthCertificate] = useState({
    file: null,
    preview: "",
    url: "",
  });
  const [businessLicense, setBusinessLicense] = useState({
    file: null,
    preview: "",
    url: "",
  });

  // Initialize from DB if edit mode
  useEffect(() => {
    if (editMode && kitchenData) {
      // existingDocs might be in "documents" or "Documents"
      const existingDocs = kitchenData.documents || kitchenData.Documents || [];

      // We'll assume the stored array is in the order [liabilityInsurance, healthCertificate, businessLicense]
      // If your backend stores them in a different order, adapt accordingly!
      if (existingDocs[0]) {
        setLiabilityInsurance({
          file: null,
          preview: existingDocs[0],
          url: existingDocs[0],
        });
      }
      if (existingDocs[1]) {
        setHealthCertificate({
          file: null,
          preview: existingDocs[1],
          url: existingDocs[1],
        });
      }
      if (existingDocs[2]) {
        setBusinessLicense({
          file: null,
          preview: existingDocs[2],
          url: existingDocs[2],
        });
      }
    }
  }, [editMode, kitchenData]);

  // Helper to identify if a file is an image
  const isImageFile = (file) => {
    return file && file.type && file.type.startsWith("image/");
  };

  // For each doc, we handle the file selection
  const handleLiabilityChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const previewUrl = URL.createObjectURL(file);
    setLiabilityInsurance({ file, preview: previewUrl, url: "" });
  };

  const handleHealthChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const previewUrl = URL.createObjectURL(file);
    setHealthCertificate({ file, preview: previewUrl, url: "" });
  };

  const handleBusinessChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const previewUrl = URL.createObjectURL(file);
    setBusinessLicense({ file, preview: previewUrl, url: "" });
  };

  // On submit: upload any changed docs and merge with existing
  const handleNext = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Determine which docs are NEW (file is not null)
      const docsToUpload = [];
      if (liabilityInsurance.file) docsToUpload.push(liabilityInsurance.file);
      if (healthCertificate.file) docsToUpload.push(healthCertificate.file);
      if (businessLicense.file) docsToUpload.push(businessLicense.file);

      // Collect existing doc URLs for anything that wasn't replaced
      const existingDocs = [];
      // For liability insurance
      if (!liabilityInsurance.file && liabilityInsurance.url) {
        existingDocs[0] = liabilityInsurance.url;
      } else {
        // Placeholder so the indexing doesn't shift
        existingDocs[0] = "";
      }

      // For health certificate
      if (!healthCertificate.file && healthCertificate.url) {
        existingDocs[1] = healthCertificate.url;
      } else {
        existingDocs[1] = "";
      }

      // For business license
      if (!businessLicense.file && businessLicense.url) {
        existingDocs[2] = businessLicense.url;
      } else {
        existingDocs[2] = "";
      }

      // If we have new docs, upload them to S3
      let uploadedUrls = [];
      if (docsToUpload.length > 0) {
        const formData = new FormData();
        docsToUpload.forEach((docFile) => {
          formData.append("docs", docFile);
        });
        const uploadUrl = `${config.apiBaseUrl}/api/kitchens/upload-docs`;
        const response = await axios.post(uploadUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        uploadedUrls = response.data.allDocs || [];
      }

      // Now we need to place the newly uploaded URLs back into the correct index (0,1,2)
      let uploadIndex = 0;
      if (liabilityInsurance.file) {
        existingDocs[0] = uploadedUrls[uploadIndex];
        uploadIndex++;
      }
      if (healthCertificate.file) {
        existingDocs[1] = uploadedUrls[uploadIndex];
        uploadIndex++;
      }
      if (businessLicense.file) {
        existingDocs[2] = uploadedUrls[uploadIndex];
        uploadIndex++;
      }

      // finalDocs is the 3-doc array in the correct order
      const finalDocs = existingDocs;

      // Save them to the DB
      const docData = { documents: finalDocs };
      const updatedKitchenData = { ...kitchenData, ...docData };

      // If in edit mode => update existing kitchen
      if (editMode) {
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        // Otherwise => new kitchen
        await updateKitchen(updatedKitchenData);
      }

      // Update local wizard state
      updateKitchenData(docData);
      nextStep();
    } catch (error) {
      console.error("Error uploading docs:", error);
      alert(
        "Failed to upload documents. Please ensure all documents are correct and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  // Cleanup local URLs on unmount
  useEffect(() => {
    return () => {
      if (liabilityInsurance.preview && liabilityInsurance.file) {
        URL.revokeObjectURL(liabilityInsurance.preview);
      }
      if (healthCertificate.preview && healthCertificate.file) {
        URL.revokeObjectURL(healthCertificate.preview);
      }
      if (businessLicense.preview && businessLicense.file) {
        URL.revokeObjectURL(businessLicense.preview);
      }
    };
  }, [liabilityInsurance, healthCertificate, businessLicense]);

  // Small helper to render a doc preview (image or link)
  const renderPreview = (docObj) => {
    // If user just selected a local file
    if (docObj.file) {
      return isImageFile(docObj.file) ? (
        <img
          src={docObj.preview}
          alt="Doc Preview"
          style={{ maxWidth: "100px", borderRadius: "4px" }}
        />
      ) : (
        <p style={{ fontWeight: "bold" }}>{docObj.file.name}</p>
      );
    } else if (docObj.url) {
      // It's an existing DB doc
      if (
        docObj.url.endsWith(".jpg") ||
        docObj.url.endsWith(".jpeg") ||
        docObj.url.endsWith(".png")
      ) {
        return (
          <img
            src={docObj.url}
            alt="Existing Doc"
            style={{ maxWidth: "100px", borderRadius: "4px" }}
          />
        );
      } else {
        return (
          <a href={docObj.url} target="_blank" rel="noopener noreferrer">
            Existing Document
          </a>
        );
      }
    }
    return null;
  };

  return (
    <form onSubmit={handleNext} className="kitchen-documents">
      <h2>{editMode ? "Edit Documents" : "Upload Documents"}</h2>

      {/* Liability Insurance */}
      <div className="doc-section">
        <h3>Liability Insurance</h3>
        <div className="doc-preview">{renderPreview(liabilityInsurance)}</div>
        <div className="docs-form-group">
          <label>Upload Liability Insurance</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            onChange={handleLiabilityChange}
          />
        </div>
      </div>

      {/* Health Certificate */}
      <div className="doc-section">
        <h3>Health Certificate</h3>
        <div className="doc-preview">{renderPreview(healthCertificate)}</div>
        <div className="docs-form-group">
          <label>Upload Health Certificate</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            onChange={handleHealthChange}
          />
        </div>
      </div>

      {/* Business License */}
      <div className="doc-section">
        <h3>Business License</h3>
        <div className="doc-preview">{renderPreview(businessLicense)}</div>
        <div className="docs-form-group">
          <label>Upload Business License</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            onChange={handleBusinessChange}
          />
        </div>
      </div>

      {/* Navigation */}
      <div className="navigation-buttons">
        <button type="button" onClick={prevStep} className="prev-btn">
          Previous
        </button>
        <button type="submit" className="next-btn">
          {loading ? "Saving..." : editMode ? "Update" : "Next"}
        </button>
      </div>

      {loading && (
        <div className="loading-indicator">
          {editMode
            ? "Updating your document details..."
            : "Saving your document details..."}
        </div>
      )}
    </form>
  );
};

export default KitchenDocuments;
