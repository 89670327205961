import React from "react";
import "./NewsletterPopup.css";

const NewsletterPopup = ({ message, type, onClose }) => {
  const getIcon = () => {
    switch (type) {
      case "success":
        return "✅"; // Success icon
      case "error":
        return "❌"; // Error icon
      default:
        return "ℹ️"; // Info icon
    }
  };

  return (
    <div className="newsletter-popup-overlay">
      <div className="newsletter-popup">
        <span className="newsletter-popup-icon">{getIcon()}</span>
        <p className="newsletter-popup-message">{message}</p>
        <button className="newsletter-popup-close" onClick={onClose}>
          ✖
        </button>
      </div>
    </div>
  );
};

export default NewsletterPopup;
