import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RestaurantHours.css";

const RestaurantHours = ({
  prevStep,
  handleSubmit,
  restaurantData,
  updateRestaurantData,
}) => {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [cutoffTime, setCutoffTime] = useState(new Date());
  const [orderDaysBefore, setOrderDaysBefore] = useState(
    restaurantData.order_cutoff_time || 0
  );

  const handleTimeChange = (field, value) => {
    const updatedTime = value.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    if (field === "start") {
      setStartTime(value);
      updateRestaurantData({
        hours_of_operation: {
          ...restaurantData.hours_of_operation,
          time: `${updatedTime} - ${endTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}`,
        },
      });
    } else if (field === "end") {
      setEndTime(value);
      updateRestaurantData({
        hours_of_operation: {
          ...restaurantData.hours_of_operation,
          time: `${startTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })} - ${updatedTime}`,
        },
      });
    }
  };

  const handleDaysBeforeChange = (e) => {
    const days = parseInt(e.target.value, 10) || 0;
    setOrderDaysBefore(days);
    updateRestaurantData({ order_cutoff_time: days });
  };

  return (
    <div className="restaurant-hours">
      <h2>Restaurant Hours</h2>
      <label>Operating Days:</label>
      <input
        type="text"
        name="days"
        value={restaurantData.hours_of_operation?.days?.join(", ") || ""}
        onChange={(e) =>
          updateRestaurantData({
            hours_of_operation: {
              ...restaurantData.hours_of_operation,
              days: e.target.value.split(",").map((day) => day.trim()),
            },
          })
        }
        placeholder="e.g., Monday, Tuesday, Wednesday"
      />
      <div>
        <label>Start Time:</label>
        <DatePicker
          selected={startTime}
          onChange={(date) => handleTimeChange("start", date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="h:mm aa"
        />
        <DatePicker
          selected={endTime}
          onChange={(date) => handleTimeChange("end", date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="h:mm aa"
        />
      </div>
      <label>Order Cutoff Time:</label>
      <DatePicker
        selected={cutoffTime}
        onChange={(date) => setCutoffTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        dateFormat="h:mm aa"
      />
      <label>Order Cutoff Time (Days Before):</label>
      <input
        type="number"
        value={orderDaysBefore}
        onChange={handleDaysBeforeChange}
        placeholder="e.g., 1 for 1 day before"
      />
      <div className="restaurant-navigation-buttons">
        <button onClick={prevStep} className="prev-btn">
          Back
        </button>
        <button onClick={handleSubmit} className="next-btn">
          Submit
        </button>
      </div>
    </div>
  );
};

export default RestaurantHours;
