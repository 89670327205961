import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./Eatz4CaterCraze.css";
import { useRestaurant } from "../../context/RestaurantContext";
import RestaurantCard from "../../components/RestaurantCard/RestaurantCard";

const Eatz4CaterCraze = () => {
  const { fetchAllRestaurants, searchRestaurants } = useRestaurant();
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    location: "",
    cuisine: "",
    minOrder: null,
  });

  useEffect(() => {
    const loadRestaurants = async () => {
      setLoading(true);
      try {
        const allRestaurants = await fetchAllRestaurants();
        setRestaurants(allRestaurants);
      } catch (error) {
        console.error("Error loading restaurants:", error);
      } finally {
        setLoading(false);
      }
    };

    loadRestaurants();
  }, [fetchAllRestaurants]);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const filteredRestaurants = await searchRestaurants(filters);
      setRestaurants(filteredRestaurants);
    } catch (error) {
      console.error("Error searching restaurants:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="eatz-page">
      <Navbar />
      <div className="eatz-content">
        <div className="eatz-search-bar">
          <input
            type="text"
            placeholder="Search location..."
            value={filters.location}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, location: e.target.value }))
            }
          />
          <select
            value={filters.cuisine}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, cuisine: e.target.value }))
            }
          >
            <option value="">All Cuisines</option>
            <option value="Italian">Italian</option>
            <option value="Vegan">Vegan</option>
            <option value="Latin">Latin</option>
            <option value="New American">New American</option>
          </select>
          <input
            type="number"
            placeholder="Min Order"
            value={filters.minOrder || ""}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, minOrder: e.target.value }))
            }
          />
          <button className="eatz-search-button" onClick={handleSearch}>
            Search
          </button>
        </div>

        <div className="restaurant-list">
          {loading ? (
            <div className="loading">
              <div className="spinner"></div>
              <p>Loading Restaurants...</p>
            </div>
          ) : restaurants.length > 0 ? (
            restaurants.map((restaurant) => (
              <div key={restaurant.restaurantID}>
                <RestaurantCard restaurant={restaurant} />
              </div>
            ))
          ) : (
            <p className="no-results">No restaurants found</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Eatz4CaterCraze;
