import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import config from "../config";

const RestaurantContext = createContext();

export const useRestaurant = () => useContext(RestaurantContext);

export const RestaurantProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [restaurantData, setRestaurantData] = useState({
    name: "",
    location: "",
    link: "",
    hours_of_operation: { days: [], time: "" },
    min_order: "",
    delivery_fee: "",
    images: [],
    address: "",
    latitude: null,
    longitude: null,
    status: "draft",
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoadingUser(false);
  }, []);

  // Create a new restaurant
  const createRestaurant = async (initialData) => {
    if (!user) {
      throw new Error("User not authenticated");
    }
    try {
      const dataToSend = {
        ...initialData,
        userID: user.userID,
        restaurantID: user.restaurantID,
        userEmail: user.email,
      };
      const response = await axios.post(
        `${config.apiBaseUrl}/api/restaurants/add`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setAllRestaurants((prev) => [...prev, response.data]);
      return response.data;
    } catch (error) {
      throw new Error("Failed to create restaurant");
    }
  };

  // Update an existing restaurant
  const updateRestaurant = async (restaurantID, updatedData) => {
    if (!user) {
      throw new Error("User not authenticated");
    }
    try {
      const response = await axios.put(
        `${config.apiBaseUrl}/api/restaurants/edit/${restaurantID}`,
        updatedData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setAllRestaurants((prev) =>
        prev.map((r) =>
          r.restaurantID === restaurantID ? { ...r, ...updatedData } : r
        )
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to update restaurant");
    }
  };

  // Fetch all restaurants, optionally filtered by userID
  const fetchAllRestaurants = useCallback(
    async ({ userID = null } = {}) => {
      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/api/restaurants/all`,
          {
            params: { userID },
            // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        );
        setAllRestaurants(response.data || []);
        return response.data;
      } catch (error) {
        throw new Error("Failed to fetch restaurants");
      }
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      user,
      loadingUser,
      allRestaurants,
      restaurantData,
      setRestaurantData,
      createRestaurant,
      updateRestaurant,
      fetchAllRestaurants,
    }),
    [
      user,
      loadingUser,
      allRestaurants,
      restaurantData,
      createRestaurant,
      updateRestaurant,
      fetchAllRestaurants,
    ]
  );

  return (
    <RestaurantContext.Provider value={contextValue}>
      {children}
    </RestaurantContext.Provider>
  );
};