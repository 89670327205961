import React, { useState, useEffect } from "react";
import { useKitchen } from "../../context/KitchenContext";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { IoMdArchive } from "react-icons/io";
import "./MyKitchens.css";

const MyKitchens = () => {
  const { fetchAllKitchens, allKitchens, setAllKitchens, user } = useKitchen();

  useEffect(() => {
    const fetchKitchens = async () => {
      if (user && user.userID) {
        const kitchens = await fetchAllKitchens({
          userID: user.userID,
          forceRefresh: true,
        });
        setAllKitchens(kitchens.reverse());
      }
    };
    fetchKitchens();
  }, [setAllKitchens, user]);

  if (!user) {
    return (
      <p className="loading">Please log in or sign up to view your kitchens.</p>
    );
  }

  if (!allKitchens || allKitchens.length === 0) {
    return <p className="loading">No kitchens available.</p>;
  }

  return (
    <div className="my-kitchens-container">
      <h1>MY KITCHEN</h1>
      <ul className="kitchens-list">
        {allKitchens.map((kitchen) => (
          <li key={kitchen.KitchenID} className="kitchen-item">
            <div className="kitchen-image">
              <img
                src={
                  kitchen.Images[0] ||
                  "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/assets/KitchenComingSoon.png"
                }
                alt={kitchen.Title}
              />
            </div>
            <div className="kitchen-details">
              <h3>
                <Link to={`/kitchen/${kitchen.KitchenID}`}>
                  {kitchen.Title || "Kitchen"}
                </Link>
              </h3>
              <p>{kitchen.Description}</p>
              <p className="location">
                City: {kitchen.City} <br /> Area: {kitchen.Size}
              </p>
              <span
                className={`badge badge-${kitchen.KitchenStatus.toLowerCase()}`}
              >
                {kitchen.KitchenStatus}
              </span>
            </div>
            <div className="kitchen-price-status">
              <p className="price">
                $
                {kitchen.HourlyRate
                  ? kitchen.HourlyRate + "/hr"
                  : kitchen.DayRate + "/day"}
              </p>
              <p className="rating">⭐ {kitchen.Rating || "N/A"}</p>
            </div>
            <div className="kitchen-actions">
              <Link
                to={`/profile/edit-kitchen/${kitchen.KitchenID}`}
                className="edit-btn"
              >
                <FaEdit /> Edit
              </Link>
              <button className="archive-btn">
                <IoMdArchive /> Archive
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyKitchens;
