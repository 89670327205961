import React, { useState, useEffect, useContext } from "react";
import "./Settings.css";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import config from "../../config";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaSpinner,
  FaUpload,
} from "react-icons/fa";

const Settings = () => {
  const { user, token, fetchUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("Fetching...");
  const [activeTab, setActiveTab] = useState("Documents");

  // Document states
  const [documents, setDocuments] = useState({
    foodHandler: { file: null, status: "Not Uploaded" },
    insurance: { file: null, status: "Not Uploaded" },
  });

  // Fetch the latest user details on component load
  useEffect(() => {
    const fetchLatestStatus = async () => {
      if (user?.userID) {
        try {
          const response = await axios.get(
            `${config.apiBaseUrl}/api/auth/user/${user.userID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const fetchedUser = response.data;

          setVerificationStatus(fetchedUser.idVerificationStatus || "N/A");

          setDocuments({
            foodHandler: {
              file: fetchedUser.documents?.foodHandler || null,
              status: fetchedUser.documents?.foodHandler
                ? "Uploaded"
                : "Not Uploaded",
            },
            insurance: {
              file: fetchedUser.documents?.insurance || null,
              status: fetchedUser.documents?.insurance
                ? "Uploaded"
                : "Not Uploaded",
            },
          });
        } catch (error) {
          console.error("Error fetching user details:", error);
          setVerificationStatus("Unable to fetch status");
        }
      }
    };

    fetchLatestStatus();
  }, [user?.userID, token]); // Added 'token' to dependency array

  const handleStartVerification = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/stripe/create-verification-session`,
        { userID: user.userID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { verificationSessionURL } = response.data;
      window.location.href = verificationSessionURL;
    } catch (error) {
      console.error("Error initiating verification:", error);
      setVerificationStatus(
        error.response?.data?.message || "Failed to initiate verification."
      );
      setLoading(false);
    }
  };

  const handleDocumentUpload = async (docType) => {
    const file = documents[docType].file;

    if (!file) {
      console.error(`No file selected for ${docType}`);
      alert("Please select a file before uploading.");
      return;
    }

    setDocuments((prevDocs) => ({
      ...prevDocs,
      [docType]: { ...prevDocs[docType], status: "Uploading..." },
    }));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("docType", docType);

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/auth/upload-document`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setDocuments((prevDocs) => ({
        ...prevDocs,
        [docType]: { file: response.data.fileUrl, status: "Uploaded" },
      }));
    } catch (error) {
      console.error(`Error uploading ${docType}:`, error);
      setDocuments((prevDocs) => ({
        ...prevDocs,
        [docType]: { ...prevDocs[docType], status: "Upload Failed" },
      }));
      alert(
        `Failed to upload ${docType}. ${
          error.response?.data?.message || "Please try again."
        }`
      );
    }
  };

  const handleFileChange = (docType, file) => {
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [docType]: { file: file, status: "Ready to Upload" },
    }));
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Uploaded":
        return <FaCheckCircle className="status-icon success" />;
      case "Uploading...":
        return <FaSpinner className="status-icon loading spin" />;
      case "Upload Failed":
        return <FaTimesCircle className="status-icon error" />;
      case "Ready to Upload":
        return <FaUpload className="status-icon ready" />;
      default:
        return null;
    }
  };

  const renderDocumentsTab = () => (
    <div className="documents-tab">
      <section className="verification-section">
        <h2>ID Verification Status: {verificationStatus}</h2>
        <div className="status-indicator">
          {verificationStatus === "Pending" && (
            <FaSpinner className="status-icon loading spin" />
          )}
          {verificationStatus === "Verified" && (
            <FaCheckCircle className="status-icon success" />
          )}
          {verificationStatus === "Failed" && (
            <FaTimesCircle className="status-icon error" />
          )}
        </div>
        <button
          className="verification"
          onClick={handleStartVerification}
          disabled={loading}
        >
          {loading ? "Redirecting to Verification..." : "Start Verification"}
        </button>
      </section>

      <section className="upload-documents">
        <h2>Upload Documents</h2>

        {/* Food Handler's Certificate */}
        <div className="document-item">
          <label htmlFor="foodHandler">Food Handler's Certificate:</label>
          <input
            type="file"
            id="foodHandler"
            accept=".pdf,.jpg,.png"
            onChange={(e) => handleFileChange("foodHandler", e.target.files[0])}
          />
          <button
            onClick={() => handleDocumentUpload("foodHandler")}
            disabled={
              !documents.foodHandler.file ||
              documents.foodHandler.status === "Uploading..."
            }
          >
            Upload
          </button>
          <div className="status-container">
            {getStatusIcon(documents.foodHandler.status)}
            <span
              className={`status-text ${documents.foodHandler.status
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
            >
              {documents.foodHandler.status}
            </span>
          </div>
          {documents.foodHandler.file &&
            typeof documents.foodHandler.file === "string" && (
              <a
                href={documents.foodHandler.file}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Document
              </a>
            )}
        </div>

        {/* Insurance */}
        <div className="document-item">
          <label htmlFor="insurance">Insurance:</label>
          <input
            type="file"
            id="insurance"
            accept=".pdf,.jpg,.png"
            onChange={(e) => handleFileChange("insurance", e.target.files[0])}
          />
          <button
            onClick={() => handleDocumentUpload("insurance")}
            disabled={
              !documents.insurance.file ||
              documents.insurance.status === "Uploading..."
            }
          >
            Upload
          </button>
          <div className="status-container">
            {getStatusIcon(documents.insurance.status)}
            <span
              className={`status-text ${documents.insurance.status
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
            >
              {documents.insurance.status}
            </span>
          </div>
          {documents.insurance.file &&
            typeof documents.insurance.file === "string" && (
              <a
                href={documents.insurance.file}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Document
              </a>
            )}
        </div>
      </section>
    </div>
  );

  const renderAccountSettingsTab = () => (
    <div className="account-settings-tab">
      <p>Account Settings content will be available soon.</p>
    </div>
  );

  const renderPersonalInfoTab = () => (
    <div className="personal-info-tab">
      <p>Personal Info content will be available soon.</p>
    </div>
  );

  const renderActiveTab = () => {
    switch (activeTab) {
      case "Documents":
        return renderDocumentsTab();
      case "Account Settings":
        return renderAccountSettingsTab();
      case "Personal Info":
        return renderPersonalInfoTab();
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      <h1>Settings</h1>
      <div className="tabs">
        <button
          className={activeTab === "Documents" ? "active" : ""}
          onClick={() => setActiveTab("Documents")}
        >
          Documents
        </button>
        <button
          className={activeTab === "Account Settings" ? "active" : ""}
          onClick={() => setActiveTab("Account Settings")}
        >
          Account Settings
        </button>
        <button
          className={activeTab === "Personal Info" ? "active" : ""}
          onClick={() => setActiveTab("Personal Info")}
        >
          Personal Info
        </button>
      </div>
      <div className="tab-content">{renderActiveTab()}</div>
    </div>
  );
};

export default Settings;
