import React from "react";
import "./RestaurantDetails.css";

const RestaurantDetails = ({ nextStep, restaurantData, updateRestaurantData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateRestaurantData({ [name]: value });
  };

  return (
    <div className="restaurant-details">
      <h2>Restaurant Details</h2>
      <label>Name:</label>
      <input
        type="text"
        name="name"
        value={restaurantData.name}
        onChange={handleChange}
      />
      <label>Location:</label>
      <input
        type="text"
        name="location"
        value={restaurantData.location}
        onChange={handleChange}
      />
      <label>Website Link:</label>
      <input
        type="text"
        name="link"
        value={restaurantData.link}
        onChange={handleChange}
      />
      <button onClick={nextStep}>Next</button>
    </div>
  );
};

export default RestaurantDetails;
