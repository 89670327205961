import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { FaLocationArrow, FaRegClock } from "react-icons/fa"; // Import React Icons
import "./CareersPage.css";

const jobs = {
  sales: [
    {
      title: "Key Account Sales Representative",
      location: "Los Angeles, CA",
      roleOverview:
        "As a Key Account Sales Representative, you’ll play a crucial role in growing our company. Your focus will be on onboarding restaurant kitchens to the marketplace and connecting food entrepreneurs to rent these spaces.",
      responsibilities: [
        "Identify and onboard restaurant kitchens to list on the platform.",
        "Build relationships with kitchen owners and food entrepreneurs to create a network of clients.",
        "Manage and promote the kitchens you onboard, ensuring they attract renters.",
        "Act as a representative of AirKitchenz AI, educating clients on the benefits of joining the platform.",
        "Develop strategies to connect food entrepreneurs with kitchens and secure rentals.",
        "Meet and exceed monthly onboarding targets to unlock additional bonuses.",
      ],
      whoWeAreLookingFor:
        "We’re not looking for extensive sales experience or flashy resumes. We want people who know the food and beverage industry and have the passion to make a difference. Whether you're a server, bartender, restaurant manager or front-of-house worker eager to transition into the tech sector, a sales professional in wine, liquor, or food service with a book of clients and direct access to restaurants, or a tech or other sales professional interested in breaking into an innovative new market, if you have knowledge of the industry and the drive to succeed, this is the perfect opportunity to make a mark.",
      whyJoinUs:
        "AirKitchenz AI is the first of its kind—an untapped market with no competition and endless possibilities. If you’re ready to bring this concept to life and help restaurants thrive, you could be part of something huge.",
      locationDetails:
        "This position is hybrid—mostly remote—with in-person meetings or visits. Candidates must be familiar with the LA and Orange County areas as we launch in these regions.",
      compensation: [
        "Flat fee of $50 for every restaurant kitchen you successfully onboard to the platform.",
        "Earn 10% commission on rentals for the first six months for the kitchens you onboard.",
        "Incentive Bonuses: Onboard more than 50 kitchens per month to receive a 10% bonus on your earnings for that month.",
        "Commission increases to 12% for three months on rentals for exceeding monthly onboarding targets.",
        "Monthly Car Allowance provided to support travel and client visits.",
      ],
      additionalDetails:
        "This is a commission-based, 1099 contractor position, offering flexibility and the ability to control your earnings based on performance.",
    },
  ],
  engineering: [],
  marketing: [],
};

const CareersPage = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [category, setCategory] = useState(null);

  const toggleJobDescription = (index) => {
    setActiveTab(activeTab === index ? null : index);
  };

  const handleCategoryClick = (categoryName) => {
    setCategory(categoryName);
  };

  return (
    <div className="CareerPage-main-container">
      <Navbar />
      <div className="CareerPage-container">
        {/* Section 1 */}
        <div className="career-section-1">
          <div className="career-section-left">
            <h2>Join Our Team!</h2>
            <p>
              We're looking for passionate and dedicated individuals to join our
              growing team. If you're ready for a new challenge, explore our
              open positions below.
            </p>
            {/* <button
              className="career-page-button"
              onClick={() =>
                (document.getElementById("job-categories").style.display =
                  "block")
              }
            >
              Open Positions
            </button> */}
          </div>
          <div className="career-section-right">
            <img
              src={require("../../assets/images/teamasset.png")}
              alt="Team"
            />
          </div>
        </div>

        <div
          id="job-categories"
          // style={{ display: "none" }}
          className="career-section-2"
        >
          <h2>Job Categories</h2>
          <div className="job-categories">
            {["sales", "engineering", "marketing"].map((cat, idx) => (
              <div key={idx} className="job-category">
                <button onClick={() => handleCategoryClick(cat)}>
                  {cat.charAt(0).toUpperCase() + cat.slice(1)}
                </button>
              </div>
            ))}
          </div>
        </div>

        {category && (
          <div className="CareerPage-job-list">
            {jobs[category].length > 0 ? (
              jobs[category].map((job, index) => (
                <div
                  className={`CareerPage-job-item ${
                    activeTab === index ? "expanded" : ""
                  }`}
                  onClick={() => toggleJobDescription(index)}
                  key={index}
                >
                  <div className="CareerPage-job-title">
                    <span>{job.title}</span>
                    <div className="CareerPage-job-details">
                      <span>
                        <FaLocationArrow /> {job.location}
                      </span>
                      <span>
                        <FaRegClock /> Full-time
                      </span>
                    </div>
                  </div>
                  {activeTab === index && (
                    <div className="CareerPage-job-description">
                      <div className="CareerPage-tab-panel">
                        <h3>Role Overview</h3>
                        <p>{job.roleOverview}</p>
                      </div>
                      <div className="CareerPage-tab-panel">
                        <h3>Key Responsibilities</h3>
                        <ul>
                          {job.responsibilities.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="CareerPage-tab-panel">
                        <h3>Who We’re Looking For</h3>
                        <p>{job.whoWeAreLookingFor}</p>
                      </div>
                      <div className="CareerPage-tab-panel">
                        <h3>Why Join Us?</h3>
                        <p>{job.whyJoinUs}</p>
                      </div>
                      <div className="CareerPage-tab-panel">
                        <h3>Location</h3>
                        <p>{job.locationDetails}</p>
                      </div>
                      <div className="CareerPage-tab-panel">
                        <h3>Compensation</h3>
                        <ul>
                          {job.compensation.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="CareerPage-tab-panel">
                        <h3>Additional Details</h3>
                        <p>{job.additionalDetails}</p>
                      </div>
                    </div>
                  )}
                  <a
                    href={`mailto:info@airkitchenz.com?subject=Application for ${job.title}`}
                    className="CareerPage-apply-btn"
                  >
                    Apply Now
                  </a>
                </div>
              ))
            ) : (
              <div className="no-jobs">
                <p>
                  We're sorry, there are no open positions for {category} at the
                  moment, but we'd love to hear from you. Please send your
                  resume and relevant work to{" "}
                  <a href="mailto:info@aitkitchenz.com">info@aitkitchenz.com</a>
                  .
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CareersPage;
