import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import { AuthContext } from "../../context/AuthContext";
import { FaEdit, FaCheckCircle } from "react-icons/fa";
import "./ProfileDetails.css";
import ProfileImage from "../../assets/images/user-profile.webp";

const ProfileDetails = () => {
  const { user, fetchUserProfile } = useContext(AuthContext);

  const [profile, setProfile] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phone: user?.phone || "",
    secondaryPhone: user?.secondaryPhone || "",
    address: user?.address || "",
    profilePicture: user?.profilePicture || "",
  });

  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [message, setMessage] = useState({ success: "", error: "" });
  const [uploading, setUploading] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (user) {
      setProfile({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        phone: user.phone || "",
        secondaryPhone: user.secondaryPhone || "",
        address: user.address || "",
        profilePicture: user.profilePicture || "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (message.success || message.error) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [message.success, message.error]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      handleUpload(selectedFile);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${config.apiBaseUrl}/api/auth/user/${user.userID}/password`,
        passwords,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setMessage({ success: "Password updated successfully!", error: "" });
    } catch (error) {
      console.error("Error updating password:", error);
      setMessage({
        success: "",
        error: "Failed to update password. Please try again.",
      });
    }
  };

  const handleUpload = async (file) => {
    if (!file) {
      setMessage({ success: "", error: "Please select a file to upload." });
      return;
    }

    const formData = new FormData();
    formData.append("profilePicture", file);

    try {
      setUploading(true);
      // Replace the URL with your actual upload endpoint
      const response = await axios.post(
        `${config.apiBaseUrl}/api/auth/upload-profile-pic`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const imageUrl = response.data.imageUrl;

      setProfile((prevProfile) => ({
        ...prevProfile,
        profilePicture: imageUrl,
      }));
      setMessage({
        success: "Profile picture uploaded successfully!",
        error: "",
      });
      setTimeout(() => setMessage({ success: "", error: "" }), 2000);
      fetchUserProfile(user.userID); // Refresh user data
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      setMessage({
        success: "",
        error: "Failed to upload profile picture. Please try again.",
      });
      setTimeout(() => setMessage({ success: "", error: "" }), 2000);
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user || !user.userID) {
      setMessage({
        success: "",
        error: "User information is missing. Please log in again.",
      });
      return;
    }

    try {
      await axios.put(
        `${config.apiBaseUrl}/api/auth/user/${user.userID}/update`,
        profile,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setMessage({ success: "Profile updated successfully!", error: "" });
      setTimeout(() => setMessage({ success: "", error: "" }), 2000);
      fetchUserProfile(user.userID);
    } catch (error) {
      console.error("Failed to update profile:", error);
      setMessage({
        success: "",
        error: "Error updating profile. Please try again.",
      });
    }
  };

  const triggerFileSelect = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="profile-details">
      {/* <h2 className="profile-heading">PROFILE</h2> */}

      {message.success && (
        <div className="success-message">{message.success}</div>
      )}
      {message.error && (
        <div className="profile-error-message">{message.error}</div>
      )}

      {/* Profile Picture Section */}
      <div className="profile-picture-section">
        <div className="profile-picture-container" onClick={triggerFileSelect}>
          <img
            src={profile.profilePicture || ProfileImage}
            alt="Profile"
            className="profile-picture"
          />
          <FaEdit className="edit-icon" />
          {/* <FaCheckCircle className="verified-icon" title="Verified User" /> */}
          {/* Hidden File Input */}
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </div>

      <form className="profile-form" onSubmit={handleSubmit}>
        {/* Existing Form Fields */}
        <div className="form-row">
          <div className="form-group">
            <label>First Name *</label>
            <input
              type="text"
              name="firstName"
              value={profile.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name *</label>
            <input
              type="text"
              name="lastName"
              value={profile.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Email *</label>
            <input type="email" name="email" value={profile.email} readOnly />
          </div>
          <div className="form-group">
            <label>Primary Phone No *</label>
            <input
              type="tel"
              name="phone"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="123-456-7890"
              value={profile.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Secondary Phone No</label>
            <input
              type="tel"
              name="secondaryPhone"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="123-456-7890"
              value={profile.secondaryPhone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={profile.address}
              onChange={handleChange}
            />
          </div>
        </div>

        <button type="submit" className="update-btn" disabled={uploading}>
          {uploading ? "Uploading..." : "Update Profile"}
        </button>
        <div className="form-row">
          <div className="form-group">
            <label>Current Password</label>
            <input
              type="password"
              name="oldPassword"
              value={passwords.oldPassword}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              value={passwords.newPassword}
              onChange={handlePasswordChange}
              required
            />
          </div>
        </div>
        <button type="submit" className="password-btn">
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ProfileDetails;
