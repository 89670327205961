import React, { useEffect } from "react";
import { useRestaurant } from "../../context/RestaurantContext";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { IoMdArchive } from "react-icons/io";
import "./MyRestaurants.css";

const MyRestaurants = () => {
  const { fetchAllRestaurants, allRestaurants, user } = useRestaurant();

  useEffect(() => {
    const fetchRestaurants = async () => {
      if (user && user.userID) {
        try {
          const restaurants = await fetchAllRestaurants({ userID: user.userID });
          console.log("Fetched restaurants:", restaurants); // Debug log
        } catch (error) {
          console.error("Error fetching restaurants:", error.message);
        }
      }
    };
    fetchRestaurants();
  }, [fetchAllRestaurants, user]);

  if (!user || !user.userID) {
    return <p className="loading">Please log in or sign up to view your restaurants.</p>;
  }

  if (!allRestaurants || allRestaurants.length === 0) {
    return <p className="loading">No restaurants available.</p>;
  }

  return (
    <div className="my-restaurants-container">
      <h1>MY RESTAURANTS</h1>
      <ul className="restaurants-list">
        {allRestaurants.map((restaurant) => (
          <li key={restaurant.restaurantID} className="restaurant-item">
            <div className="restaurant-image">
              <img
                src={
                  restaurant.images && restaurant.images.length > 0
                    ? restaurant.images[0]
                    : "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/assets/RestaurantComingSoon.png"
                }
                alt={restaurant.name || "Restaurant"}
              />
            </div>
            <div className="restaurant-details">
              <h3>
                <Link to={`/restaurant/${restaurant.restaurantID}`}>
                  {restaurant.name || "Restaurant"}
                </Link>
              </h3>
              <p>{restaurant.location}</p>
              <p className="min-order">Min Order: ${restaurant.min_order}</p>
              <span
                className={`badge badge-${restaurant.status?.toLowerCase() || "unknown"}`}
              >
                {restaurant.status || "Unknown"}
              </span>
            </div>
            <div className="restaurant-price-status">
              <p className="delivery-fee">Delivery Fee: ${restaurant.delivery_fee}</p>
              <p className="rating">⭐ {restaurant.rating || "N/A"}</p>
            </div>
            <div className="restaurant-actions">
              <Link
                to={`/profile/edit-restaurant/${restaurant.restaurantID}`}
                className="edit-btn"
              >
                <FaEdit /> Edit
              </Link>
              <button className="archive-btn">
                <IoMdArchive /> Archive
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyRestaurants;
