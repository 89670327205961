import React, { useState } from "react";
import axios from "axios";
import "./RestaurantPrice.css";

const RestaurantPrice = ({
  prevStep,
  nextStep,
  restaurantData,
  updateRestaurantData,
}) => {
  const [minOrder, setMinOrder] = useState(restaurantData.min_order || "");
  const [deliveryFee, setDeliveryFee] = useState(
    restaurantData.delivery_fee || ""
  );

  const handlePricingChange = (e) => {
    const { name, value } = e.target;
    updateRestaurantData({ [name]: parseFloat(value) });
  };

  const saveAndNext = () => {
    updateRestaurantData({ min_order: minOrder, delivery_fee: deliveryFee });
    nextStep();
  };

  return (
    <div className="restaurant-price">
      <h2>Restaurant Pricing</h2>
      <label>Minimum Order:</label>
      <input
        type="number"
        name="min_order"
        value={minOrder}
        onChange={(e) => setMinOrder(e.target.value)}
      />
      <label>Delivery Fee:</label>
      <input
        type="number"
        name="delivery_fee"
        value={deliveryFee}
        onChange={(e) => setDeliveryFee(e.target.value)}
      />

      <div className="restaurant-navigation-buttons">
        <button onClick={prevStep} className="prev-btn">
          Back
        </button>
        <button onClick={saveAndNext} className="next-btn">
          Next
        </button>
      </div>
    </div>
  );
};

export default RestaurantPrice;
