import React from 'react';
import './HowItWorks.css';
import BrowseImage from './BROWSE KITCHEN IMG.png';
import BookImage from './D1619E5A-A503-4726-B173-FA9896642F54 2.png';
import GrowImage from './4905879.png';

const HowItWorks = () => {
  return (
    <div className="how-it-works-container">
      <h2 >See how AirKitchenz can help</h2>
      <div className="steps">
        <div className="step">
          <div className="home-step-content">
            <h3>01 - Find a Kitchen</h3>
            <p>Search for a kitchen in your preferred location with the equipment and features that suit your needs.</p>
          </div>
          <div className="step-image-container">
            <img src={GrowImage} alt="Browse a Kitchen" className="step-image" />
          </div>
        </div>
        <div className="step">
          <div className="home-step-content">
            <h3>02 - Book a Kitchen</h3>
            <p>Select your perfect kitchen, book, and make the payment easily.</p>
          </div>
          <div className="step-image-container">
            <img src={BookImage} alt="Book a Kitchen" className="step-image" />
          </div>
        </div>
        <div className="step">
          <div className="home-step-content">
            <h3>03 - Grow Your Business</h3>
            <p>Use our AI tools to expand, optimize, and explore new markets with minimal risk—no build-out costs or bureaucracy.</p>
          </div>
          <div className="step-image-container">
            <img src={BrowseImage} alt="Grow Your Business" className="step-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;